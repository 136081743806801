import React, { forwardRef } from 'react'
import cx from 'classnames'

import styles from './Button.module.scss'

const Button = forwardRef(
  (
    { 
      children,
      className,
      href,
      isDisabled,
      onClick,
      target,
      variant,
      ...props
    },
    ref,
  ) => {
    const Element = href ? 'a' : 'button'

    const disabled = isDisabled ? { 'aria-disabled': true } : {}

    const addedProps = href
      ? {
          href,
          onClick,
          target,
          rel:
            target && target === '_blank' ? 'noopener noreferrer' : undefined,
        }
      : {
          type: 'button',
          ...disabled,
          onClick: (event) => {
            if (isDisabled) return event.preventDefault()
            return onClick(event)
          },
        }

    return (
      <Element
        ref={ref}
        className={cx(styles.button, styles[variant], className)}
        {...addedProps}
        {...props}
      >
        {children}
      </Element>
    )
  },
)

export default Button
