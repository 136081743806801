import React, { useRef, useState } from 'react'
import cx from 'classnames'

import styles from './Menu.module.scss'

import { onBlur as onBlurHelper } from './helpers'

export const WIDTH = 200

const Menu = ({ button, children, open, style }) => {
  const container = useRef(null)

  const [isMenuOpen, setMenuOpen] = useState(false)

  const onBlur = onBlurHelper({ container, setMenuOpen })
  const onClick = () => setMenuOpen(!isMenuOpen)

  return (
    <div ref={container} style={{ position: 'relative' }}>
      {button({ onBlur, onClick, isMenuOpen, style })}
      
      {isMenuOpen && (
        <div className={cx(styles.menu, styles[open])}>
          {children({ onBlur, onClick, isMenuOpen })}
        </div>
      )}
    </div>
  )
}

export default Menu
