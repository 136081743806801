import React from 'react'

import styles from './FeaturedStores.module.scss'

import FeaturedStoresListItem from './ListItem'

const FeaturedStores = ({ storesDetails }) => {
  return (
    <div className={styles.featuredStores}>
      <div className={styles.header}>
        <h2>Today's Featured Stores</h2>
      </div>

      <div className={styles.featuredStoresContainer}>
        {storesDetails.map((storeDetail) => {
          return (
            <FeaturedStoresListItem
              key={storeDetail.store_id}
              linkDetail={storeDetail}
            />
          )
        })}
      
        <FeaturedStoresListItem />
      </div>
    </div>
  )
}

export default FeaturedStores