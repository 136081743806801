export const getFullDateAndTime = ({ date, showWeekday, monthFormat }) => {
  const options = {
    weekday: showWeekday ? 'long' : undefined,
    year: 'numeric',
    month: monthFormat,
    day: 'numeric',
    time: 'long',
    hour: 'numeric',
    minute: '2-digit',
  }

  const dateObject = new Date(date).toLocaleString('en-US', options)

  return `${dateObject}`
}