import React, { useState, useEffect } from 'react'
import { DetectAdblock } from '@scthakuri/adblock-detector'

import styles from './InterstitialClick.module.scss'

import InterstitialClickAdBlockMessage from './AdBlockMessage'
import InterstitialClickRedirectkMessage from './RedirectMessage'

const MAXIMUM_CHARACTERS = 20

const InterstitialClick = ({ currentUser, storeDetails }) => {
  const nickname = currentUser.nickname.substring(0, MAXIMUM_CHARACTERS)
  const [startDetect, setStartDetect] = useState(true)
  const [detected, setDetected] = useState(false)
  
  useEffect(() => {
    if(startDetect ) {
      DetectAdblock((enable) => {
        setStartDetect(false)
        setDetected(enable)
      })
    }
  }, [startDetect])

  if (startDetect) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={storeDetails.logo_url || 'https://via.placeholder.com/150x40'}/>
      </div>
      
      {detected ? (
        <InterstitialClickAdBlockMessage 
          storeDetails={storeDetails}
        />
      ) : (
        <InterstitialClickRedirectkMessage
          nickname={nickname}
          storeDetails={storeDetails}
        />
      )}
    </div>
  )
}

export default InterstitialClick