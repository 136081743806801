import React from 'react'

import BoxAdsListItem from './ListItem'

const BoxAds = () => {
  return (
    <div className="hot-deals">
      <BoxAdsListItem />
      <BoxAdsListItem />
      <BoxAdsListItem />
      <BoxAdsListItem />
      <BoxAdsListItem />
    </div>
  )
}

export default BoxAds