import React from 'react'

import styles from './UserMenu.module.scss'

import Menu from '../Menu'

import UserMenuButton from './Button'
import UserMenuLink from './Link'

const UserMenu = ({ currentUser }) => {
  return (
    <Menu open="right" button={({ onBlur, onClick, legend }) => UserMenuButton({ currentUser, onBlur, onClick, legend })}>
      {() => (
        <div className={styles.userMenu}>
          <ul>
            <UserMenuLink href="/account/my-cash">
              My Cash
            </UserMenuLink>

            <UserMenuLink href="/account/my-purchases">
              My Purchases
            </UserMenuLink>
            
            <UserMenuLink href="/account/my-network">
              My Network
            </UserMenuLink>

            <UserMenuLink href="/account/my-referral-codes">
              My Referral Codes
            </UserMenuLink>
            
            <UserMenuLink href="/account/my-profile">
              My Profile
            </UserMenuLink>

            <li>
              <form className={styles.form} name="logout-form" action="/account/sign-out">
                <button type="submit" className={styles.button}>
                  Sign Out
                </button>
              </form>
            </li>
          </ul>
        </div>
      )}
    </Menu>
  )
}

export default UserMenu
