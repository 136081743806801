import React from 'react'
import { Container } from 'react-grid-system'

import styles from './Layout.module.scss'

const Layout = ({ children }) => {
  return (
    <main className={styles.layout}>
      <Container>{children}</Container>
    </main>
  )
}

export default Layout