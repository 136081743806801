import React from 'react'
import { Col, Hidden, Row, Visible } from 'react-grid-system'

import styles from './MyCash.module.scss'

import AccountSidebar from '../AccountSidebar'
import Layout from '../Layout'

const MyCash = ({ user, networkCount, userCurrency, pendingCashBalance, pendingCashBackSavings, pendingNetworkSavings, withdrawCashBalance, withdrawCashBackSavings, withdrawNetworkSavings, lifetimeCashEarned, lifetimeSavings, lifetimeNetworkSavings }) => {
  const dollars = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: userCurrency,
  })  

  return (
    <Layout>
      <Row>
        <Col lg={4} xxl={4}>
          <AccountSidebar
            user={user} 
            userCurrency={userCurrency}
            networkCount={networkCount} 
            totalAmountEarned={lifetimeCashEarned}
          />
        </Col>

        <Col lg={8} xxl={8}>
          <div className={styles.heading}>
            <h1>My Cash</h1>
          </div>

          <div className={styles.cashInfoContainer}>
            <div className={styles.secondaryHeading}>
              <h2>Pending Cash<sup>*</sup></h2>
            </div>
            
            <div className={styles.cashInfo}>
              <div className={styles.cash}>
                <div className={styles.cashTitle}>
                  <h2>Cash</h2>
                  <h2>Balance</h2>
                </div>

                <p>{dollars.format(pendingCashBalance)}</p>
              </div>

              <div className={styles.cash}>
                <br />
                <br />
                
                <Visible xs sm>
                  <div className={styles.horizontalLine}></div>
                </Visible>
                
                <Hidden xs sm>
                  <p className={styles.mathOperator}>=</p>
                </Hidden>
              </div>

              <div className={styles.cash}>
                <div className={styles.cashTitle}>
                  <h2>My Cash Back</h2>
                  <h2>Savings</h2>
                </div>
                
                <p>{dollars.format(pendingCashBackSavings)}</p>
              </div>
              
              <div className={styles.cash}>
                <br />
                <br />
                
                <p className={styles.mathOperator}>+</p>
              </div>
              
              <div className={styles.cash}>
                <div className={styles.cashTitle}>
                  <h2>My Downstream</h2>
                  <h2>Network Rewards</h2>
                </div>
                <p>{dollars.format(pendingNetworkSavings)}</p>
              </div>
            </div>

            <div className={styles.postInfo}>
              <sup>*</sup>Your pending cash may fluctuate until purchases are finalized
            </div>
          </div>

          {withdrawCashBalance > 0 && (
            <>
              <div className={styles.cashInfoContainer}>
                <div className={styles.secondaryHeading}>
                  <h2>Available Cash to Withdraw</h2>
                </div>
                
                <div className={styles.cashInfo}>
                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>Cash</h2>
                      <h2>Balance</h2>
                    </div>

                    <p>{dollars.format(withdrawCashBalance)}</p>
                  </div>

                  <div className={styles.cash}>
                    <br />
                    <br />
                    
                    <Visible xs sm>
                      <div className={styles.horizontalLine}></div>
                    </Visible>
                    
                    <Hidden xs sm>
                      <p className={styles.mathOperator}>=</p>
                    </Hidden>
                  </div>

                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>My Cash Back</h2>
                      <h2>Savings</h2>
                    </div>
                    
                    <p>{dollars.format(withdrawCashBackSavings)}</p>
                  </div>
                  
                  <div className={styles.cash}>
                    <br />
                    <br />
                    
                    <p className={styles.mathOperator}>+</p>
                  </div>
                  
                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>My Downstream</h2>
                      <h2>Network Rewards</h2>
                    </div>
                    <p>{dollars.format(withdrawNetworkSavings)}</p>
                  </div>
                </div>
              </div>
            </>
          )}
            
          
          {lifetimeCashEarned > 0 && (
            <>
              <div className={styles.cashInfoContainer}>
                <div className={styles.secondaryHeading}>
                  <h2>Lifetime Cash Earned</h2>
                </div>
                
                <div className={styles.cashInfo}>
                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>Cash</h2>
                      <h2>Earned</h2>
                    </div>

                    <p>{dollars.format(lifetimeCashEarned)}</p>
                  </div>

                  <div className={styles.cash}>
                    <br />
                    <br />
                    
                    <Visible xs sm>
                      <div className={styles.horizontalLine}></div>
                    </Visible>
                    
                    <Hidden xs sm>
                      <p className={styles.mathOperator}>=</p>
                    </Hidden>
                  </div>

                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>My Cash Back</h2>
                      <h2>Savings</h2>
                    </div>
                    
                    <p>{dollars.format(lifetimeSavings)}</p>
                  </div>
                  
                  <div className={styles.cash}>
                    <br />
                    <br />
                    
                    <p className={styles.mathOperator}>+</p>
                  </div>
                  
                  <div className={styles.cash}>
                    <div className={styles.cashTitle}>
                      <h2>My Downstream</h2>
                      <h2>Network Rewards</h2>
                    </div>

                    <p>{dollars.format(lifetimeNetworkSavings)}</p>
                  </div>
                </div>
              </div>
            </>
          )}

        </Col>
      </Row>
    </Layout>
  )
}

export default MyCash