import React from 'react'
import { Col, Hidden, Row, Visible } from 'react-grid-system'
import cx from 'classnames'

import styles from './Store.module.scss'

import Button from '../Button'
import Layout from '../Layout'

import StoreCouponsListItem from './CouponsListItem'

const Store = ({ store, storeDetails, coupons, similarStores, similarStoresDetails }) => {
  const isSimilarStoresEmpty = similarStores.length === 0;
  const isCouponsEmpty = coupons.length === 0;

  return (
    <Layout>
      <Row>
        <Col lg={4} xxl={4}>
          <div className={styles.storeLogoContainer}>
            <div className={styles.storeLogo}>
              <img src={`${storeDetails.retailer_logo_url || 'https://picsum.photos/240/100'}`} className={styles.image} />
            </div>
          </div>
        </Col>

        <Col lg={8} xxl={8}>
          <div className={styles.storeBannerContainer}>
            <div className={styles.storeBanner}>
              <h2 className={styles.title}>{storeDetails.display_commission_string_html}</h2>
              {storeDetails.impression_pixel && (
                <img src={storeDetails.impression_pixel} />
              )}
              {storeDetails.home_page_url && (
                <div className={styles.button}>
                  <Button variant="tertiary" href={storeDetails.home_page_url} target="_blank">Shop Now</Button>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.storeInfoContainer}>
        <Row>
          <Col lg={4} xxl={4}>
            <div className={styles.content}>
              <div
                className={styles.storeDescription}
                dangerouslySetInnerHTML={{ __html: store.description }}
              />
              
              {!isSimilarStoresEmpty && (
                <Hidden xs sm md>
                  <div className={styles.similarStoresContainer}>
                    <h2 className={styles.title}>Similar Stores</h2>
                    
                    <div className={styles.similarStoresListContainer}>
                      {similarStores.map((store, index) => {
                        return (
                          <div key={index} className={styles.similarStore}>
                            <a href={`/stores/${store.slug}`}>
                              <div className={styles.storeName}>
                                {store.name}
                              </div>

                              <div className={styles.storeCashBack}>
                                {similarStoresDetails[index].display_commission_string_html}
                                {similarStoresDetails[index].impression_pixel && (
                                  <img src={similarStoresDetails[index].impression_pixel} />
                                )}
                              </div>
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Hidden>
              )}
            </div>
          </Col>

          {!isCouponsEmpty && (
            <Visible xs sm md>
              <Col>
                <div className={cx(styles.content, styles.couponsContainer)}>
                  <h2 className={cx(styles.title, styles.couponsTitle)}>Today's Hot Deals, Coupons & Promo Codes</h2>

                  <div className={styles.couponsListContainer}>
                    {coupons.map((coupon, index) => {
                      return (
                        <StoreCouponsListItem
                          key={coupon.id}
                          couponDetails={coupons[index]}
                        />
                      )
                    })}
                  </div>
                </div>
              </Col>
            </Visible>
          )}
          
          <Col lg={8} xxl={8}>
            {!isCouponsEmpty && (
              <Hidden xs sm md>
                <div className={cx(styles.content, styles.couponsContainer)}>
                  <h2 className={cx(styles.title, styles.couponsTitle)}>Today's Hot Deals, Coupons & Promo Codes</h2>

                  <div className={styles.couponsListContainer}>
                    {coupons.map((coupon, index) => {
                      return (
                        <StoreCouponsListItem
                          key={coupon.id}
                          couponDetails={coupons[index]}
                        />
                      )
                    })}
                  </div>
                </div>
              </Hidden>
            )}

            {!isSimilarStoresEmpty && (
              <Visible xs sm md>
                <div className={styles.content}>
                  <h2 className={cx(styles.title, styles.couponsTitle)}>Similar Stores</h2>
                  
                  <div className={cx(styles.similarStoresContainer, styles.similarStoresListContainer)}>
                    {similarStores.map((store, index) => {
                      return (
                        <div key={index} className={styles.similarStore}>
                          <a href={`/stores/${store.slug}`}>
                            <div className={styles.storeName}>
                              {store.name}
                            </div>

                            <div className={styles.storeCashBack}>
                              {similarStoresDetails[index].display_commission_string_html}
                              {similarStoresDetails[index].impression_pixel && (
                                <img src={similarStoresDetails[index].impression_pixel} />
                              )}
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Visible>
            )}
            
            <div className={cx(styles.content, { [styles.cashbackTermsContainer]: !isCouponsEmpty })}>
              <h2 className={cx(styles.title, styles.cashbackTitle)}>Cash Back Terms, Conditions & Restrictions</h2>
              
              <div
                className={styles.cashbackContainer}
                dangerouslySetInnerHTML={{ __html: store.cash_back_terms }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Store