import React, { forwardRef } from 'react'
import cx from 'classnames'

import styles from './Input.module.scss'

const Input = forwardRef(
  (
    {
      id,
      label,
      value,
      after,
      helperText,
      hasError,
      errorMessage,
      isDisabled = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={styles.inputContainer}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}

        <div className={styles.inputWrapper}>
          <input
            ref={ref}
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            className={cx(styles.input, {
              [styles.inputHasError]: hasError,
            })}
            disabled={isDisabled}
            {...props}
          />

          {!!after && <div className={styles.after}>{after}</div>}
        </div>

        {(hasError || helperText) && (
          <div className={styles.messageContainer}>
            {hasError && (
              <span className={styles.errorMessage}>{errorMessage}</span>
            )}
            {!hasError && helperText && (
              <span className={styles.helperText}>{helperText}</span>
            )}
          </div>
        )}
      </div>
    )
  },
)

export default Input
