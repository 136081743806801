import React from 'react'
import { Row, Col} from 'react-grid-system'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'

import '@reach/accordion/styles.css'
import styles from './InterstitialClick.module.scss'

import Button from '../Button'

const AdBlockMessage = ({ storeDetails }) => {
  const onClick = () => window.location.reload()

  return (
    <Row>
      <Col offset={{ md: 1, xl: 2, xxl: 3 }} xs={12} md={10} xl={8} xxl={6}>
        <div>
          <div className={styles.adBlockText}>
            Oops, it looks like an <span className={styles.adBlockTextHighlight}>ad blocker or your browser</span> is preventing you from
            <span className={styles.adBlockTextEmphasized}>
              {` ${storeDetails.display_commission_string_html_present_participle} at ${storeDetails.name}.`}
            </span>
          </div>

          <div className={styles.adBlockText}>
            Disabling your ad blocker or whitelisting Buygoon.com allows
            us to properly track your shopping. Please disable and click
            Refresh.
          </div>
          
          <div className={styles.buttonContainer}>
            <Button className={styles.buttonRefresh} variant="primary" onClick={onClick}>
              Refresh
            </Button>
          </div>

          <Accordion collapsible className={styles.accordion}>
            <AccordionItem>
              <AccordionButton className={styles.accordionButton}>
                <span>Learn more about ad blockers</span>
                <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </AccordionButton>

              <AccordionPanel className={styles.accordionPanel}>
                <div className={styles.accordionBody}>
                  <p>To ensure you receive your cash back rewards, please disable any ad blockers while shopping through Buygoon.com.</p>
                  <p>Ad blockers are tools built into your web browser or through browser extensions (such as uBlock, AdGuard, Ghostery or AdBlock) that can prevent us from tracking your purchases.</p>
                  <p>If we can't track your purchases, we can't credit your account with cash back. We appreciate your understanding and look forward to helping you save more!</p>
                  <p>Find out how to disable your ad blocker here: <a href="https://www.wikihow.com/Disable-AdBlock" target="_blank">https://www.wikihow.com/Disable-AdBlock</a></p>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          
          <hr></hr>

          <a href={storeDetails.redirect_url}>
            Shop without earning Cash Back
          </a>
        </div>
      </Col>
    </Row>
  )
}

export default AdBlockMessage