import React from 'react'
import { Row, Col } from 'react-grid-system'

import FeaturedStores from '../FeaturedStores'
import HotDeals from '../HotDeals'
import Layout from '../Layout'
import TopCategories from '../TopCategories'

const Home = ({ storesDetails, storesHotDealsDetails, categories }) => {
  return (
    <Layout>
      <Row>
        <Col>
          <FeaturedStores storesDetails={storesDetails} />
        </Col>
      </Row>

      <Row>
        <Col>
          <HotDeals storesHotDealsDetails={storesHotDealsDetails} />
        </Col>
      </Row>

      {/* Hiding this until we have more stores */}
      {/* <Row>
        <Col>
          <TopCategories categories={categories} />
        </Col>
      </Row> */}
    </Layout>
  )
}

export default Home