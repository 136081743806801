import React from 'react'
import { Container } from 'react-grid-system'

const Layout = ({ children }) => {
  return (
    <main>
      <Container>{children}</Container>
    </main>
  )
}

export default Layout