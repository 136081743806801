import React, { useEffect } from 'react'

import styles from './InterstitialClick.module.scss'

const REDIRECT_DELAY = 5000

const RedirectMessage = ({ nickname, storeDetails }) => {
  const redirectUrl = storeDetails.redirect_url

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.replace(redirectUrl)
      // window.location = redirectUrl
    }, REDIRECT_DELAY)

    return () => clearTimeout(timeoutId);
  }, [])

  return (
    <div>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>One moment please</div>
        <div className={styles.ellipsis}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className={styles.simpleText}>
        {`Congratulations ${nickname}, you're on your way to`}
      </div>

      <h3 className={styles.cashBackText}>
        {storeDetails.display_commission_string_html}
      </h3>
      
      <div className={styles.simpleText}>
        Nothing more for you to do. It&apos;s that simple!
      </div>
      
      <div className={styles.smallText}>
        Cash back earned will be automatically added to your Buygoon account.
      </div>
    </div>
  )
}

export default RedirectMessage