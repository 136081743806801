export const copyTextToClipboard = ({ text, success, error }) => {
  navigator.clipboard.writeText(text).then(() => {
    if (success instanceof Function) {
      success()
    }
  }, (err) => {
    if (error instanceof Function) {
      error(err)
    }
  })
}