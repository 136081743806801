import React from 'react'

import styles from './CategoriesMenu.module.scss'

import Button from '../Button'

const CategoriesMenuButton = ({ onBlur, onClick }) => (
  <Button
    className={styles.button}
    aria-label="Toggle Dropdown Menu"
    variant="transparent"
    onBlur={onBlur}
    onClick={onClick}
    isDisabled={false}
  >
    <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>
    
    <span>
      Categories
    </span>
  </Button>
)

export default CategoriesMenuButton
