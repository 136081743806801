import React from 'react'
import { Row, Col } from 'react-grid-system'

import Layout from '../Layout'
import PageHeading from '../PageHeading'

const About = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <PageHeading>
            <h1>About Buygoon</h1>
          </PageHeading>

          <section>
            <p>At Buygoon, we're pioneering a shift in online shopping, moving beyond mere savings to enable actual earnings. We make shopping more rewarding!</p>
          </section>
          <section>
            <h2>Unlock Your Earning Potential</h2>
            <p>Our platform uniquely stands out by offering a way to earn money without the necessity of personal shopping. This innovative approach emerges from recognizing the untapped potential within your social network, asking the compelling question, "What's the true value of your social network?" With Buygoon, we provide a pathway for anyone to generate extra income effortlessly, merging the convenience of online shopping with the power of social connectivity.</p>
          </section>
          <section>
            <h2>How It Works</h2>
            <p>Key to Buygoon's distinction is our model that allows you to earn without making purchases yourself. This revolutionary concept in the online shopping realm means that by simply participating in Buygoon and inviting others, you're not only securing a new avenue for your own income but also empowering your invitees with the opportunity to benefit financially. This sharing of opportunity is at the core of what we do, fostering a community where everyone can thrive.</p>
            <p><strong>Note:</strong> Buygoon does not guarantee a specific amount of earnings. The potential income one can earn through Buygoon depends on the size of their downstream network—how many people they invite and how much shopping those individuals do with our retail partners. This aspect underscores the communal and collaborative nature of the platform, where your earning potential grows with the active participation and shopping habits of your network.</p>
          </section>
          <section>
            <h2>Earn and Share</h2>
            <p>Our platform leverages your social connections, integrating renowned retail brands, cashback rewards, and the social media experience to enhance online shopping. With Buygoon, you earn cash bonuses whenever anyone in your network shops online, creating a potent opportunity for passive income that doesn't require you to spend.</p>
            <p>We also encourage you to amplify your impact and earnings by sharing your shopping finds and victories. Whether it's a great deal, a dream vacation, or the perfect gift, sharing these discoveries can now yield financial rewards for you and your network. In doing so, you're not just enriching your social interactions but also introducing others to the earning capabilities of Buygoon, creating a supportive ecosystem of mutual financial empowerment.</p>
          </section>
          <section>
            <h2>Join Us</h2>
            <p>By inviting others to Buygoon, you're offering more than an entry into a new platform; you're opening the door to a novel method of making money online that benefits all participants. Whether for enjoyment, as a hobby, or potentially as a home business to replace a 9 to 5, Buygoon aims to make the online shopping and sharing experience both rewarding and enjoyable.</p>
            <p>We invite you to explore Buygoon for yourself and see how our platform redefines earning through online shopping and social sharing, bearing in mind the variable nature of potential earnings.</p>
          </section>
        </Col>
      </Row>
    </Layout>
  )
}

export default About