import React from 'react'
import { Row, Col } from 'react-grid-system'
import { ToastContainer, toast } from 'react-toastify'

import styles from './MyReferralCodes.module.scss'

import AccountSidebar from '../AccountSidebar'
import Button from '../Button'
import FlashMessage, { AUTO_CLOSE_DELAY } from '../FlashMessage'
import Layout from '../Layout'

import MyReferralCodesShareSection from './ShareSection'

import { copyTextToClipboard } from './helpers'

const MyReferralCodes = ({ user, userCurrency, networkCount, totalAmountEarned, referralCodes, shareLinks, flashMessages }) => {
  const { referral_code } = referralCodes[0]
  const referralUrl = `${window.location.origin}/r/${referral_code}`
  
  const onClick = ({ referralUrl }) => {
    copyTextToClipboard({
      text: referralUrl,
      success: toast.success('Copied to clipboard!', { autoClose: AUTO_CLOSE_DELAY }),
    })
  }

  return (
    <Layout>
      {flashMessages.length > 0 && flashMessages.map((flashMessage) => {
        const { message_type, message } = flashMessage

        return (
          <FlashMessage
            key={message}
            messageType={message_type}
            message={message}
          />
        )
      })}

      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

      <Row>
        <Col lg={4} xxl={4}>
          <AccountSidebar
            user={user}
            userCurrency={userCurrency}
            networkCount={networkCount}
            totalAmountEarned={totalAmountEarned}
          />
        </Col>

        <Col lg={8} xxl={8}>
          <div className={styles.heading}>
            <h1>My Referral Codes</h1>
          </div>

          <div className={styles.section}>
            <h2>Grow Your Network</h2>

            <p>
              Share your personal referral link with your friends and followers to grow your network and earn passive income.
              {/* Create up to 4 custom links that you can post or share with your friends and followers.
              <br /><br />
              Then choose the link you want to post or share.
              <br /><br />
              You can even post or share a different link to different friends or followers. */}
              <br /><br />
              When someone clicks your link and signs up, they will automatically be added to the First Ripple of your Buygoon Downstream Network.
            </p>
          </div>            

          <div className={styles.section}>
            <h2>Your personal referral link to share is:</h2>
            <br />
          
            <div className={styles.inputContainer}>
              <Row>
                <Col md={8} lg={8}>
                  <div className={styles.referralUrlContainer}>
                    <div className={styles.radioInput}>
                      <input
                        type="radio"
                        name="referral_code"
                        id={referral_code}
                        value={referral_code}
                        checked
                        readOnly
                      />
                    </div>
                    
                    <span>
                      {`${window.location.origin}/r/`}
                    </span>

                    <span>
                      {referral_code}
                    </span>
                  </div>
                </Col>
                
                <Col md={4}>
                  <div className={styles.buttonContainer}>
                    <Button variant="primary" onClick={() => onClick({ referralUrl })}>
                      <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                      </svg>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <MyReferralCodesShareSection
            referralUrl={referralUrl}
            shareLinks={shareLinks}
          />

        </Col>
      </Row>
    </Layout>
  )
}

export default MyReferralCodes