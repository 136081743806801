import React from 'react'

import Button from '../Button'

const BoxAdsListItem = () => {
  return (
    <div className="hot-deals-list-item">
      <img className="shop-logo" src="https://static.ebates.com/img/store/8055/yankeecandle_icon_150x40.gif"width="150" height="40" />

      <div className="hot-deal-info">
        <h3>Hot Deals</h3>
      </div>
    </div>
  )
}

export default BoxAdsListItem