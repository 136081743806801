import React from 'react'

import styles from './Store.module.scss'

import Button from '../Button'

const StoreCouponsListItem = ({ couponDetails }) => {
  const isLongDescriptionEmptyOrDescriptionsEqual = !couponDetails.long_description || couponDetails.long_description.trim().length === 0 || (couponDetails.long_description.trim().toLowerCase() === couponDetails.short_description.trim().toLowerCase());

  return (
    <div className={styles.couponsListItem}>
      <div className={styles.couponInfo}>
        <h3>{couponDetails.short_description}</h3>

        <div className={styles.cashbackPercentageContainer}>
          <svg xmlns="https://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
          </svg>

          <h3 className={styles.cashback}>{couponDetails.display_commission_string_html}</h3>

          {couponDetails.impression_pixel && (
            <img src={couponDetails.impression_pixel} />
          )}
        </div>

        {!isLongDescriptionEmptyOrDescriptionsEqual && (
          <div className={styles.longDescription}>
            {couponDetails.long_description}
          </div>
        )}
      </div>

      <div className={styles.button}>
        <Button variant="secondary" href={couponDetails.coupon_link_path} target="_blank">Shop Deal</Button>
      </div>
    </div>
  )
}

export default StoreCouponsListItem