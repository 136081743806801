import React, { useState } from 'react'
import { Row, Col } from 'react-grid-system'

import styles from './MyPurchases.module.scss'

import AccountSidebar from '../AccountSidebar'
import Layout from '../Layout'
import Pagination from '../Pagination'

import { getFullDateAndTime } from '../Date/helpers'

const PAGE_SIZE = 15
const CURRENT_PAGE = 1

const MyPurchases = ({ user, userCurrency, purchasesDetails, networkCount, totalAmountEarned }) => {
  const [currentPage, setCurrentPage] = useState(CURRENT_PAGE)
  const paginatedPurchasesDetails = purchasesDetails.slice((currentPage - 1) * PAGE_SIZE, (currentPage * PAGE_SIZE))
  const totalPages = Math.ceil(purchasesDetails.length / PAGE_SIZE)

  const dollars = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: userCurrency,
  })  

  return (
    <Layout>
      <Row>
        <Col lg={4} xxl={4}>
          <AccountSidebar
            user={user}
            userCurrency={userCurrency}
            networkCount={networkCount}
            totalAmountEarned={totalAmountEarned}
          />
        </Col>
         
        <Col lg={8} xxl={8}>
          <div className={styles.heading}>
            <h1>My Purchases</h1>
          </div>

          <div className={styles.cashInfo}>
            <div className={styles.tableWrapper}>
              {purchasesDetails.length <= 0 && (
                <h2>It's kind of empty in here. Perfect time to <a href="/stores">Make Shopping More Rewarding</a>&trade;</h2>
              )}

              {purchasesDetails.length > 0 && (
                <table className={styles.table}>
                <thead>
                  <tr>
                    <th className={styles.leftAlign} scope="col">Date</th>
                    <th className={styles.leftAlign} scope="col">Store</th>
                    <th className={styles.rightAlign} scope="col">Amount</th>
                    <th className={styles.rightAlign} scope="col">Cash Back</th>
                    <th scope="col">Status</th>
                    <th scope="col">ID #</th>
                  </tr>
                </thead>
                
                <tbody>
                  {paginatedPurchasesDetails.map((purchaseDetail) => {
                    return (
                      <tr key={purchaseDetail.id}>
                        <td className={styles.leftAlign} data-label="Date">{getFullDateAndTime({ date: purchaseDetail.earned_at, showWeekday: false, monthFormat: 'short'})}</td>
                        <td className={styles.leftAlign} data-label="Store">{purchaseDetail.store_name}</td>
                        <td className={styles.rightAlign} data-label="Purchase Amount">{dollars.format(purchaseDetail.purchase_amount)}</td>
                        <td className={styles.rightAlign} data-label="Cash Back">{dollars.format(purchaseDetail.cash_back_amount)}</td>
                        <td data-label="Status">{purchaseDetail.status}</td>
                        <td data-label="ID #">{purchaseDetail.id.toString().padStart(6, '0')}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              )}
            </div>
          </div>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
            />
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export default MyPurchases