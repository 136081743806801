import React from 'react'
import { Row, Col } from 'react-grid-system'

import Layout from '../Layout'
import PageHeading from '../PageHeading'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <PageHeading>
            <h1>Privacy Policy</h1>
          </PageHeading>
          
          <p>At Buygoon Inc. (&ldquo;Buygoon&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo;) we respect your privacy rights and are committed to protecting the information we collect from you online. To further this commitment, we have developed the following website Privacy Policy (&ldquo;Privacy Policy&rdquo;) which explains how we collect, use, disclose, update and protect information you provide to us on <a href="/">www.buygoon.com</a> (the &ldquo;Website&rdquo;), and which is also integrated within the Buygoon Terms Of Use. This Privacy Policy applies only to information submitted and collected online at the Website. This Privacy Policy does not apply to information collected on websites operated by third parties.</p>
          <hr/>
          
          <h4>Updates To Policy</h4>
          
          <p>To accommodate changes in our service, the technology, and legal developments, this Policy may change over time. We may add, change, or remove portions of this Policy when we feel it is appropriate to do so. Whenever we update this Policy we will change the date on the Policy to indicate when it was last changed. Please check back from time to time to ensure that you are aware of any changes to our privacy practices.</p>
          <p>Privacy Policy Update: December 23, 2014</p>
          <hr/>
          
          <h4>What information does Buygoon collect?</h4>
          
          <p>At Buygoon we collect information reasonably necessary to fulfill your online requests and our legitimate business objectives. We collect two types of information: (i) personal information and (ii) non-personal information.</p>
          
          <p><em>Personal information</em> may include your name, address, phone number, and e-mail address. You can always choose not to provide us with any of your personal information. However, if you make this choice, we may not be able to provide you with the product, service, or information that you requested.</p>
          
          <p><em>Non-personal information</em> does not identify you as an individual. You agree that Buygoon may collect and use any and all non-personal information relating to you. For example, we may ask you for your postal code to assist us in determining any applicable tax withholdings and governmental reporting requirements we might have. We may also collect and summarize customer information for statistical purposes in a format that no longer identifies the individual. Demographic Information is anonymous information such as a person&rsquo;s age, date of birth, gender, hobbies, interests, zip/postal code or country. Demographic Information is not considered Personal Information unless it is linked to Personal Information about a specific user. Demographic Information may be collected for an activity that also requires Personal Information, in which case we may combine the Demographic Information with your Personal Information. Combining data allows us to customize the notices we send you and the content we provide you according to your interests and preferences. If we do combine your Demographic Information with your Personal Information, we then treat all of that data as Personal Information. If you choose not to provide certain Demographic Information, you may not be able to use certain features on this website. Aggregate Information is anonymous data about individuals that is grouped together to study a group or category of services or users. Aggregate Information is not considered Personal Information unless it is linked to Personal Information about a specific user.</p>
          <hr/>
          
          <h4>How do you provide consent to collect personal information?</h4>
          
          <p>You may provide your consent to collect personal information in two ways: (i) implied consent; and (ii) express consent. In some cases, your consent is implied if we ask you to provide personal information with a stated purpose. For example, in some circumstances, we can only permit you to withdraw monies from Buygoon to which you may be entitled if you provide your email, full legal name and mailing address. In other cases, we will ask you to give your express consent to use your personal information to advise you of products or services that may be of interest to you. All the information we collect about you may be combined to help us tailor our communications to you and to develop world-class products and services.</p>
          <hr/>
          
          <h4>How do you withdraw consent to use personal information?</h4>
          
          <p>Because we strive to limit our use of your personal information to only those uses that form the basis of our service, the social network nature of our Website, and the strict anti-spam laws that may apply to Buygoon, if you wish to withdraw your consent for Buygoon to use your personal information for its stated purposes, we will need to terminate your membership in Buygoon. Please email <a href="mailto:admin@buygo%6F%6E%2E%63om">admin@buygoon.com</a> to withdraw your express consent and thereby terminate your membership.</p>
          <hr/>

          <h4>Information Buygoon collects from you</h4>

          <p>The following are some examples of the ways that we may collect your express consent and information about you:</p>
          <p><b>(i) Buygoon Memberships:</b></p>
          <p>By signing up for a Buygoon membership account you are expressly consenting to receive electronic communications from Buygoon relating to Buygoon, it&rsquo;s retail partners, and products and/or services offered by Buygoon and/or its retail partners. We may send to you, daily, weekly or from time to time, e-mail or other communications regarding goods or services we believe will interest you. The ability to send you electronic communications is the essence of the Buygoon system. We may also use the information to measure and improve the effectiveness of our Websites or our marketing endeavors and to tailor our online offerings to your preferences. If you do not wish to participate in such a promotional system, please do not open a Buygoon membership.</p>
          
          <p><b>(ii) Contests, sweepstakes, promotions and/ or surveys:</b></p>
          <p>From time to time, we may run contests, sweepstakes, promotions and/ or surveys. If you participate, you may be asked for contact information as well as additional optional survey information (for example, product preferences). Information from entries will be used to contact you if you win. We may also summarize survey information in a manner that no longer personally identifies the contest entrants for analysis, but we will not share personal information from entries. All contests are subject to rules that may further stipulate how we may use the information we collect and will be available with each particular contest.</p>
          
          <p><b>(iii) Information Buygoon collects automatically:</b></p>
          <p>Upon opening a membership with Buygoon, we assign you an internal identification. When you click a link (of a Buygoon retail partner) on the Website and navigate to the websites(s) of our retail partners, an identification tag or marker may be assigned to you, such that the retail partner can report financial details of the purchases you make on the retail partner&rsquo;s website. Buygoon and its retail partners (or their agents) correlate the amount of your purchases to your internal Buygoon identification so that Buygoon can calculate cash bonuses that may be payable to you and to people in your network. At no time does Buygoon collect or disclose what products or services you buy. Like many other websites, we also automatically collect certain non-personal information regarding website users that does not personally identify you. Examples of such include the Internet Protocol (IP) address of your computer, the IP address of your Internet Service Provider, the date and time you access the website, the Internet address of the website from which you linked directly to the Website, the operating system you are using, the sections of the Website you visit, the Website pages read and images viewed, and the content you download from the Website.</p>
          <p><b>(iv) Cookies:</b></p>
          <p>The Website may use "cookies", a technology that installs a small amount of information on a website user's computer to permit the website to recognize future visits using that computer. Cookies enhance the convenience and use of the website. For example, the information provided through cookies is used to recognize you as a previous user of the website, to offer personalized web page content and information for your use, to track your activity at the website, to respond to your needs, and to otherwise facilitate your website experience. You may choose to decline cookies if your browser permits, but doing so may affect your use of the website and your ability to access certain features of the website or engage in transactions through the website.</p>
          
          <p><b>(v) Log files:</b></p>
          <p>We may maintain log files, which contain Internet Protocol ("IP") addresses. An IP address is a numeric address that is assigned to your computer by your Internet Service Provider. We do not link IP addresses to personal information, unless we notify you or unless necessary to enforce our website&rsquo;s terms and conditions. In general, we use log files to monitor traffic on our website and to troubleshoot technical problems. In the event of abuse, however, we may block certain IP addresses.</p>
          
          <p><b>(vi) Other technologies:</b></p>
          <p>We may use a common Internet technology called "Web beacons" (also called "Web bugs," "action tags," "pixel trackers," or "clear GIFs") on our website. Web beacons are small graphic images placed on a web page, web-based document, or in an email message that are designed to allow us or vendors to collect certain information and monitor user activity on our website. Web beacons are invisible to the user because they are typically very small (only 1-by-1 pixel) and the same color as the background of the web page, document or email message. We do not use Web beacons to collect Personal Information about you. We may use Web beacons to collect non-personal information such as the IP address of the computer that the Web beacon is sent to, the URL of the page the Web user comes from, and the time the Web beacon is activated (such as when you view the Web page, advertisement, or email that contains the Web beacon). In general, we do not link such non-personal information to personal information; however, we may use Web beacons to record whether individual users view emails from Buygoon. This allows us to more effectively manage our database of active registered members. We may also use Web beacons to display emails in the appropriate HTML or text format. From time to time, we may permit vendors to place Web beacons on our website or in emails sent on our behalf. Web beacons used by our vendors collect the same types of information and are treated in the same manner as specified above.</p>
          
          <p><b>(vii) Tracking information:</b></p>
          <p>We may use non-personal information to compile tracking information reports regarding website user demographics, website traffic patterns, and then provide those reports to advertisers and others. None of the tracking information in the reports can be connected to the identities or other personal information of individual users. We may also link tracking information with personal information voluntarily provided by website users. Once such a link is made, all of the linked information is treated as personal information and will be used and disclosed only in accordance with this Policy.</p>
          
          <p><b>(viii) Links to other websites:</b></p>
          <p>Our website may contain links to other websites or Internet resources. When you click on one of those links you are contacting another website or Internet resource. Buygoon has no responsibility or liability for or control over those other websites or Internet resources or their collection, use and disclosure of your personal information. We encourage you to read the privacy policies of those other sites to learn how they collect and use information about you.</p>
          <hr/>
          
          <h4>Does Buygoon disclose the information it collects?</h4>
          
          <p>(i) Buygoon does not sell your personal information. However, we may share your information with agents and/or third parties acting on our behalf or providing services to Buygoon. Buygoon may share your personal information with certain service providers in order to provide products and services to you, to verify and validate information, and to address customer service matters. To reduce the risk of fraud or misuse of personal information, Buygoon may also verify your personal information, including but not limited to your address and credit card information, with third party service providers. We will use commercially reasonable efforts to ensure that your personal information is used in a manner that is consistent with this Policy. If you do not wish us to provide your personal information to those service providers, we may be unable to provide you with Buygoon products and services. Buygoon may use non-personal information at its discretion.</p>
          <p>(ii) Buygoon may disclose your personal and non-personal information as permitted or required by law, to a government institution or other person or entity that has asserted its lawful authority to obtain the information, where we have reasonable grounds to believe the information could be useful in the investigation of unlawful activity, to comply with a subpoena or warrant or an order made by a court, person or entity with jurisdiction to compel the production of information, to comply with court rules regarding the production of records and information, and/or to our accountants and legal counsel.</p>
          <hr/>

          <h4>Supplementing information</h4>
          
          <p>From time to time we may supplement information you give us with information from other sources, such as information validating your address or other available information you have provided us. This is to help us maintain the accuracy of the information we collect and to help us provide better service.</p>
          <hr/>
          
          <h4>How does Buygoon protect my information?</h4>
          
          <p>The security of your personal information is a high priority for Buygoon. We maintain appropriate safeguards and current security standards to protect your personal information, whether recorded on paper or captured electronically, against unauthorized access, disclosure, or misuse. Although we do not take responsibility, and hereby expressly waive all liability, for any theft, misuse, unauthorized disclosure, loss, alteration or destruction of data, via hack, theft, loss or otherwise, we take reasonable precautions to prevent such unfortunate occurrences.</p>
          <hr/>
          
          <h4>How can I verify my information?</h4>
          
          <p>Subject to certain exceptions prescribed by law, and provided we can authenticate your identity, you can check your information to verify, update, or correct it at any time by contacting us at <a href="mailto:admin@buygoon.com">admin@buygoon.com</a>. Some requests may involve extensive costs to Buygoon. As a result Buygoon, may request the inquirer to absorb some or all costs of the inquiry prior to conducting a review.</p>
          <hr/>
          
          <h4>When is my information removed?</h4>
          
          <p>We keep your information only as long as we need it for the products and services you're using and for a reasonable time thereafter or to meet any legal requirements. We have retention standards that meet these requirements. We destroy your information when we determine it is no longer needed, or we remove your personally identifiable information.</p>
          <hr/>
          
          <h4>How can I contact Buygoon?</h4>
          
          <p>Buygoon is responsible for all personal information under its control. Our Privacy Manager is accountable for Buygoon's compliance with the principles described here. If you have any questions, concerns or complaints about the privacy policies please contact us at the below:</p>
          <p><b>Email:</b> <a href="mailto:admin@buygoon.com"> admin@buygoon.com</a></p>
          <p className={StyleSheet.bold}>
            Mail:
            <ul className="vcard">
              <li>Buygoon Inc.</li>
              <li>Attention: Privacy Manager</li>
              <li>3-35 Stone Church Road</li>
              <li>Suite #183</li>
              <li>Hamilton, Ontario</li>
              <li>Canada, L9K 1S5</li>
            </ul>
          </p>
          <hr/>
          
          <h4>International users:</h4>
          <p>Visitors to Buygoon&rsquo;s Website from outside of Canada are advised that this Privacy Policy is intended to meet the laws and requirements of Canada, which may not necessarily be the same as the laws and regulations of your home country. If you are not a resident of Canada and have any concerns about the law, Buygoon urges you to not provide personal information. Any information that you do provide to Buygoon will be treated in accordance with the laws and regulations of the Provinces and the applicable laws of Canada.</p>
          <br/>
        </Col>
      </Row>
    </Layout>
  )
}

export default PrivacyPolicy
