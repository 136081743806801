import React from 'react'

import styles from './StoresMenu.module.scss'

import Menu from '../Menu'

import StoresMenuButton from './Button'
import StoressMenuLink from './Link'

const StoresMenu = ({ retailers }) => {
  return (
    <Menu open="left" button={StoresMenuButton}>
      {() => (
        <div className={styles.storesMenu}>
          <ul>
            <StoressMenuLink href="/stores">
              <span className={styles.showAll}>
                Show All Stores
              </span>
            </StoressMenuLink>

            {retailers.map(({ name, slug }) => (
              <StoressMenuLink key={slug} slug={slug} href={`/stores/${slug}`}>
                {name}
              </StoressMenuLink>
            ))}
          </ul>
        </div>
      )}
    </Menu>
  )
}

export default StoresMenu
