import React from 'react'
import cx from 'classnames'

import styles from './HamburgerMenu.module.scss'

const HamburgerMenuLink = ({ href, title, url, children }) => {
  return (
    <li className={cx(styles.listItem, { [styles.selected]: url === href })}>
      <a className={cx(styles.listItemLink)} href={href}>
        {children}

        <span>
          {title}
        </span>
      </a>
    </li>
  )
}

export default HamburgerMenuLink