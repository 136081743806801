import React from 'react'
import cx from 'classnames'

import styles from './StoresMenu.module.scss'

const StoresMenuLink = ({ slug, href, children }) => {
  const url = window.location.href
  const regex = new RegExp(`\\b${slug}\\b`)
  const selected = regex.test(url)

  return (
    <li>
      <a href={href} className={cx(styles.storesMenuLink, { [styles.selected]: selected })}>
        {children}
      </a>
    </li>
  )
}

export default StoresMenuLink