import React from 'react'

import styles from './Pagination.module.scss'

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  return (
    <div className={styles.paginationContainer}>
      <button
        className={styles.navigation}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage - 1 < 1}
      >
        Previous
      </button>

      {Array.from({ length: totalPages }).map((_, index) => {
        return (
          <button
            key={index}
            className={styles.navigation}
            onClick={() => setCurrentPage(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        )
      })}

      <button
        className={styles.navigation}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage + 1 > totalPages}
      >
        Next
      </button>
    </div>
  )
}

export default Pagination