import React from 'react'
import { Row, Col } from 'react-grid-system'

import Layout from '../Layout'
import PageHeading from '../PageHeading'

const TermsAndConditions = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <PageHeading>
            <h1>Terms and Conditions</h1>
          </PageHeading>
          
          <p><h4>Welcome to Buygoon!</h4></p>
          
          <p><b>PLEASE NOTE CAREFULLY:</b>THIS IS A LEGALLY BINDING AGREEMENT BETWEEN YOU AND BUYGOON INC. (<b>"BUYGOON"</b>), STATING THE TERMS AND CONDITIONS THAT GOVERN YOUR USE OF BUYGOON’S WEBSITE(S) (THE<b>"SERVICE"</b>).</p>
          <p>YOU MUST READ ALL OF THIS AGREEMENT, ALL RULES AND POLICIES RELATING TO THE SERVICE (including without limit, the Buygoon Privacy Policy, which is incorporated herein by this reference), ABOUT, UPDATES, MATERIALS POSTED OTHERWISE ON THE WEBSITE(S), ADDITIONAL TERMS, AND SOFTWARE LICENSES ISSUED BY BUYGOON (COLLECTIVELY, THE <b>"AGREEMENT"</b>).</p>
          <p>NO CHANGES OR ADDITIONS TO, OR DELETIONS FROM THE AGREEMENT, BY YOU ARE ACCEPTABLE AND BUYGOON MAY AT ANY TIME AND WITHOUT NOTICE REFUSE ACCESS TO ITS SERVICE FOR NONCOMPLIANCE WITH ANY PART OF THIS AGREEMENT.</p>
          <p>THIS SERVICE IS ONLY AVAILABLE TO INDIVIDUALS AGED 18 YEARS OR OLDER. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE 18 YEARS OF AGE AND THAT YOUR USE OF THE SERVICE INCLUDES THE LEGAL ABILITY TO ENTER INTO AGREEMENTS AND/OR TO MAKE TRANSACTIONS ELECTRONICALLY. YOUR USE OF THE SERVICE IS FOR PERSONAL ENTERTAINMENT PURPOSES ONLY. You are not permitted to use or attempt to use the Service from any country or jurisdiction wherein such use is illegal or otherwise prohibited for any reason and hereby agree to abide by all laws, regulations, policies and rules that apply to you, and you further agree that Buygoon may and may permit others on its behalf to use technologies or any other means to verify your compliance and take steps Buygoon deems reasonable and appropriate to prevent your unauthorized use or attempted use of the Service.</p>
          <p>IN ORDER TO SIGN UP AS A MEMBER OF BUYGOON, YOU MUST ACCEPT AND ABIDE BY ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT BY CLICKING "AGREE" ON THE SIGN-UP WEBPAGE. IF YOU DO NOT AGREE TO ALL OF THE AGREEMENT TERMS AND CONDITIONS DO NOT CLICK "AGREE" AND DO NOT USE THE SERVICE.</p>
          <p>BY REGISTERING AS A MEMBER TO PARTICIPATE IN THE BUYGOON PROGRAM YOU ARE ACKNOWLEDGING, REPRESENTING AND WARRANTING TO BUYGOON THAT YOU HAVE READ THIS AGREEMENT AND THAT YOU EXPRESSLY AGREE TO ALL OF ITS TERMS AND CONDITIONS.</p>
          <p>Buygoon reserves the right, at its sole discretion, at any time and from time to time, to amend, update, revise, supplement, and otherwise change this Agreement and to impose new or additional rules, policies, terms, or conditions on your use of the Service, by posting the revised terms on the Service's website. Such changes, updates, revisions, supplements, modifications,  and additional rules, policies, terms, and conditions will be effective immediately and incorporated into this Agreement. Your continued use of the Service after any such changes, updates, revisions, supplements, modifications, and additional rules, policies, terms, and conditions will be evidence of your acceptance thereof and continuous agreement to be bound by this Agreement. It is your responsibility to keep your knowledge of this Agreement up to date. Any use by you of the Buygoon website and/or any of its services will constitute your acceptance of this Agreement including any and all changes to this Agreement, which will be effective immediately upon posting of same by Buygoon on the website. All of the terms and conditions of this Agreement will remain in full force and effect, and applicable to you, during the full period of your Buygoon membership; any and all provisions hereof which could reasonably be interpreted as applicable to you after the termination of your Buygoon membership, are hereby deemed to apply in perpetuity, or for such shorter period as may otherwise be practical. Any and all terms and conditions of the agreements between Buygoon and its retail partners, that are applicable to this Agreement, are hereby incorporated into this Agreement by this reference; you agree it is your sole responsibility to, and that you will, review and abide by all such incorporated terms and conditions.</p>
          
          <hr/>
          <h4>1. Overview</h4>

          <p>Buygoon is an online shopping service that permits its Members (each, a <b>"Member"</b>) to earn cashback on their purchases, and the purchases of their extended friend network (to 4 degrees of separation) created via Buygoon, made on Buygoon’s partnered stores’ websites after clicking through from Buygoon. This Agreement is a limited license only, granted by Buygoon to you personally, to use the Service, in accordance with all terms and conditions herein.</p>
          
          <hr/>
          <h4>2. Membership</h4>

          <p>Enrolling as a Member of Buygoon is simple and free. All you need to do is enter your e-mail address, create your own password, complete our sign-up form, and click the "Join Now" button. If you were referred to Buygoon by a friend, please use the referral link they sent you or enter the e-mail address of the friend that referred you to Buygoon, so we can place you directly underneath them within their network, which in no way reduces the network that you can build underneath you. If you do not enter the e-mail of the friend that referred you to Buygoon, we will insert you into the Buygoon network either randomly or otherwise at our sole discretion.</p>
          <p>Membership in Buygoon and participation in its cashback system is offered to each Member by Buygoon in its sole discretion. You must be an individual person that is 18 years of age to join Buygoon. <b>Only one membership is permitted per person;</b> if you create more than one membership, whether directly or indirectly for your benefit through another entity, Buygoon will be entitled to prohibit your use and access to such accounts at any time, immediately and without notice and you agree you will then have forfeited to Buygoon any and all cashback in such membership account(s) at the time and at all times in the future; you expressly agree this is a fair and fundamental part of this Agreement. You may not use scripts or disguised redirects to derive any benefit, whether financial or otherwise, from Buygoon. You must have your own Internet access and valid e-mail address to be eligible for membership with Buygoon.</p>
          <p>In the event Buygoon reasonably believes you have in any way breached this Agreement, including without limitation, by undertaking any use of the Buygoon website or any of its services for any illegal or fraudulent activity, Buygoon may review your account, with or without its retail partners, and may at any time thereafter terminate your membership. Upon such termination you will forfeit any and all monies that may be payable to you by Buygoon. You expressly agree that such termination and remedy for the benefit of Buygoon is reasonable and part of the fundamental bargain of this Agreement. During any such review of your Buygoon account and use of the Services, Buygoon may suspend your access to your account for that period of time that Buygoon decides in necessary to fully complete the review. In addition to the foregoing, Buygoon reserves the right to pursue any other legal action or remedies available to it, through which it may seek actual, direct, indirect, compensatory and or punitive damages. Buygoon may also pursue criminal charges wherever applicable. Buygoon may at any time and for any other reason, terminate your membership, in which case, your sole remedy will be the payment by Buygoon of any monies Buygoon agrees is payable to you as of the date on which Buygoon terminates your membership.</p>
          <p>You expressly acknowledge and agree that by becoming a Member of Buygoon you permit us to use information relating to your membership with Buygoon, including without limit the payments made to you by Buygoon, in our marketing, advertising and promotional activities, provided that we will not disclose your personal contact information without prior consent.</p>
          
          <hr/>
          <h4>3. Earning Cashback</h4>
          <p>You can earn cashback on your own online purchase and from the purchases of the people in your Buygoon downstream (which are your friends, their friends, their friends and their friends!)</p>

          <p><b><em>(a) How does it work?</em></b></p>
          <p>Buygoon partners with various online stores that pay Buygoon when its Members shop on those stores' websites. Buygoon then shares the money it receives from those stores with the Buygoon Member network, according to this Agreement.</p>
          <p>You understand, acknowledge and agree, that you must first login to your Buygoon account and click through from the Buygoon website to the Buygoon partnered store websites in order for you to be able to generate and earn cashback on your purchases.</p>
          <p>Buygoon is not responsible, nor liable, for any inaccuracy of any cashback percentages or amounts displayed on the Buygoon website. Buygoon will only be responsible for sharing the cash that it actually and permanently receives from its retail partners as a direct and trackable result of a Member’s purchase(s) on the Buygoon retail partners’ websites. If Buygoon does not receive such cash from its retail partners, or if Buygoon is not provided with accurate tracking information for cashback, Buygoon will have no obligation whatsoever to its Member(s) to pay cashback on purchases made by the Buygoon Member from the given store(s), notwithstanding that there may have been a cashback percentage or amount displayed on the Buygoon website. A Member’s sole remedy for any claim against Buygoon for cashback will be the Member’s right to terminate its membership and be paid amounts that Buygoon agrees were payable to the Member as of the date of such membership termination. You acknowledge and agree that any obligation to pay you cashback is governed by this Agreement between you and Buygoon, and that no retail partner of Buygoon has any obligation to you whatsoever in relation to cashback that may be payable to you pursuant to the Buygoon membership system. The determination of whether or not the purchases made by you through a Buygoon retail partner’s website qualifies for cashback is at the sole and final discretion of Buygoon. Notwithstanding anything else that may be to the contrary in this Agreement, in the event you disagree with Buygoon in relation to your account or amounts payable or paid to you, your sole remedy is to terminate your Buygoon membership account.</p>
          <p>You expressly acknowledge and agree that under this Agreement, your only entitlement may be to cashback calculated by Buygoon as due to you pursuant to Buygoon`s cashback system; you will not at any time have any entitlement to portions of cashback that are generated on your purchases and that are allocated by Buygoon to itself and/or other Members. This agreement creates no direct or indirect legal relationship, right or liability between you and any other Buygoon Member, or the cashback allocated to such persons by Buygoon.</p>
          <p>Please note that the purchase amounts on which cashback may be calculated may exclude taxes, fees, shipping, discounts, credits, returns, cancellation, gift wrapping, warranties and other amounts otherwise applied to the purchase amount.</p>
          <p>In order for a purchase to qualify for inclusion in the Buygoon program, you must complete your purchase during the same shopping session you start after clicking a given store, product or service link on the Buygoon website. If you navigate away to another site before completing your purchase on the given store's website, or if you use coupons, codes or links that are not provided by Buygoon, you may not earn cashback from Buygoon on your purchase. You understand and agree that gift cards and gift certificates will not generate cashback except where expressly indicated, or possibly, where a store’s primary products are such gift cards or certificates.</p>
          <p>You must not disable cookies on your computer, or you may not earn cashback from Buygoon on your purchases. You agree to Buygoon utilizing cookies on your computer; you agree to third party stores utilizing cookies on your computer and that you will review and agree with such store cookie policies prior to using this Service. As used herein, "computer" may be a desktop, laptop, tablet, smartphone or similar device now known or hereinafter invented. You acknowledge and agree that Buygoon may track you navigating on and off of the Buygoon website by assigning you a unique identifier, or by any other means, so that we can correlate your Buygoon membership identity against reports we receive from our retail partners to calculate how much cashback is due to you and to the Members in your network.</p>
          <p>You acknowledge and agree that use of the Service may require and you will be solely responsible for acquiring at your own expense, one or more compatible devices, Internet access and certain software, which may from time to time and at any time be changed and/or require updates and/or upgrades, all of which may be subject to fees, charges, and costs payable solely by you. In no event will Buygoon be responsible to you or any other entity for any such fees, charges or costs related thereto.</p>
         
          <p><b><em>(b) Earning cashback on your own purchases</em></b></p>
          <p>You can earn cashback as a portion of the amount of your purchases online from stores that are partnered with Buygoon. The amount of cashback that you earn from your own online purchases will vary from product to product and store to store. Where products and services are specifically advertised on the Buygoon website, we may show the cashback percentage offered by the given store for the given product or service.</p>
         
          <p><b><em>(c) Earning cashback from your Buygoon downstream</em></b></p>
          <p>In addition to cashback on your own online purchases, you can earn a 10% cash bonus on the cashback earned by all the people in your Buygoon downstream when they shop online from stores that are partnered with Buygoon. You can invite as many friends as you want and your downstream may also contain an unlimited number of referred friends. Your Buygoon downstream will be limited to four layers deep (i.e. your referred friends, their referrals, their referrals and their referrals). Buygoon may, from time to time, and at any time, add Members to any of your downstream layers, and may offer other incentives for membership and/or shopping, whether or not monetary in nature. The fact that Buygoon may add new Members to any given Member’s Buygoon downstream, does not obligate Buygoon to add new Members to any other Member’s downstream.</p>

          <hr/>
          <h4>4. Terms Of Product and/or Service Purchases By Buygoon Members</h4>

          <p>When you navigate on the Buygoon website, you may choose a given store or product or other offer displayed on the Buygoon website by clicking the accompanying shopping link. You will then be navigated directly to the given store’s website to complete your purchases. All transactions by you for all products and services displayed on the Buygoon website will be undertaken directly between you and the given store. All the terms and conditions of such purchase transactions will be determined directly between you and the given store. Buygoon makes no representation, warranty, covenant or agreement, and expressly waives any and all liability, in relation to your purchase of all products and/or services on the stores’ websites. You hereby expressly waive any and all claims and causes of action against Buygoon that in any way relate to such purchases. Buygoon does not in any way endorse any given store, product or service by virtue of same being displayed on the Buygoon website.</p>
          
          <hr/>
          <h4>5. How Buygoon Pays Its Members</h4>

          <p>Buygoon currently pays all amounts payable to its Members exclusively via PayPal in either Canadian or US currency at its sole election. It is your sole responsibility and liability to ensure the accuracy of all information that you provide to Buygoon and PayPal. Buygoon expressly waives any and all liability for payments that you do not receive as a result of inaccurate information provided to Buygoon or PayPal. Buygoon reserves the right at any time and from time to time to modify or change its payment methods. You waive any and all claims against Buygoon in relation to any failure on the part of PayPal.</p>
          <p>On or about the end of the month following the month in which Buygoon permanently receives cashback from its retail partner(s), Buygoon will permit you to access the amounts of cashback in your Buygoon account in order that you may request a transfer of monies available to you from Buygoon via PayPal. Please note that stores may take multiple months to pay Buygoon cashback generated by your shopping on such stores’ website. As noted above, no cashback will be payable to you by Buygoon unless and until Buygoon has received cashback from the respective store(s). Buygoon does not issue periodic statements of account, but rather, makes available account reports for user-specified timelines within the Buygoon website.</p>
          <p>Please note, that PayPal has a current single payment maximum amount of $9,999.00, which it may change at any time and from time to time, and which will apply to you. Accordingly the maximum single payment amount you may request from Buygoon will be $9,999.00. All payments made to you from Buygoon via PayPal will be subject to and net of any and all service charges, expenses and other fees that may be charged to Buygoon and to you by PayPal.</p>
          <p>You hereby represent, warrant, acknowledge and agree, that: (i) you will be solely liable for reporting any and all amounts that you receive from Buygoon to the appropriate governmental authorities as may be required, and you will be solely liable for paying any and all income taxes and similar taxes payable in respect of monies that you receive from Buygoon; and (ii) Buygoon may withhold from you any and all amounts that any applicable governmental or like authority requires Buygoon to withhold. You acknowledge and agree that Buygoon may withhold any and all amounts payable to you unless and until you supply Buygoon with all information that it requires to comply with such applicable governmental or like authorities. Without limiting the foregoing, in the event you are a resident of the United States, and that the amounts payable to you by Buygoon exceed $600 in a given calendar year, Buygoon may be required by law to send you a 1099 form. In such event, we may require additional information from you to complete the 1099 form.</p>
          
          <hr/>
          <h4>6. Anti-Spam And Prohibited Promotions</h4>

          <p>Buygoon maintains a zero tolerance policy in relation to spam. We do not endorse or condone the use of spam to promote Buygoon. The essence of Buygoon is friends telling friends about Buygoon and the cashback available from products and services advertised on Buygoon. You are solely responsible for understanding and adhering to all spam laws that may apply to you in your given country. Without limiting the foregoing, do not send unsolicited e-mails and do not post electronic communications on any websites in violation of those websites user agreements or anti-spam policies.</p>
          <p>Buygoon reserves the right at all times to prohibit any and all promotions undertaken by its Members that relate to Buygoon and/or its retail partners. Without limiting the foregoing sentence, you specifically represent, warrant, covenant and agree that you will not:</p>
          <ul>
            <li>Register or use any brand names, trademarks or domain names that are the same as or confusingly similar to Buygoon and/or any of its domain names and/or trademarks, or those of our partner stores, or typo-squat on the name(s) or trademark(s) of Buygoon or any of our partner stores or use any brand names, trademarks or domain names that are the same as or confusingly similar to those of Buygoon's within any email address or social media account name (and you hereby agree that in the event of such registration, use or typo-squatting, you will immediately upon request assign all such domains and trademarks to Buygoon and you hereby provide Buygoon with a specific and limited power of attorney, which is irrevocable and coupled with an interest, to prepare and execute all such documents of assignment in your name and on your behalf);</li>
            <li>Undertake any search engine promotion that includes keyword bidding on any names or trademarks of Buygoon or any of our partner stores, or words that are confusingly similar;</li>
            <li>Undertake any advertisement that includes any names or trademarks of Buygoon or any of our retail partners, or words that are confusingly similar;</li>
            <li>Undertake any advertisement or promotion that in any way presents Buygoon or any of our retail partners in any manner, way or light, that may be viewed as negative, disparaging, inaccurate or harmful, including without limit, promotions involving content that is of an adult or political nature, or that promotes hatred or intolerance;</li>
            <li>Represent yourself as an employee or agent of Buygoon or any of our retail partners; or,</li>
            <li>Falsely misrepresent yourself in relation to Buygoon or the terms and conditions of Buygoon, or represent to anyone any specific amount of money they may earn as a result of becoming a Member of Buygoon.</li>
          </ul>
          
          <hr/>
          <h4>7. Your Account</h4>
          <p>You are solely responsible and liable for keeping your membership password confidential. Buygoon will be entitled to communicate and undertake actions with any individual who provides your e-mail address and password whether or not that person is actually you or someone authorized by you. It is therefore extremely important that you keep your password confidential. We suggest you memorize your password and do not record it in written form anywhere. You must be logged in to your membership account in order to view and/or change any account information. Buygoon may, but is not obligated to, permit you to change your membership details via the Buygoon website. You hereby expressly consent to Buygoon releasing your membership information to third parties as may be required by Buygoon to efficiently and properly process payments, and/or by law, judicial process, audit, suit and/or investigation.</p>
          
          <hr/>
          <h4>8. Electronic Communications From Buygoon</h4>
          <p>Because we need to be able to communicate with you in order to operate the Buygoon website and its services, when you join Buygoon, and where not in contravention of applicable SPAM laws, each time you login to your Buygoon Member account, you expressly agree to receive any and all electronic communications from Buygoon, including without limitation via e-mail, cellular text message, telephone, fax, social network postings and updates, and other electronic communications, whether or not now known or hereinafter invented. If you do not wish to receive any such communications from Buygoon you may not be permitted to open a Buygoon Member account, or you may be provided with the option to immediately terminate your existing Buygoon Member account. In the event you elect to terminate your Buygoon Member account in such context, your sole remedy will be payment of all amounts Buygoon agrees are payable to you from Buygoon as of the date of such termination.</p>
          
          <hr/>
          <h4>9. Intellectual Property</h4>
          <p>Subject to the ownership by our retail partners of their respective names, logos and trademarks, all rights in and to all content on the Buygoon website are exclusively owned by Buygoon. Without limiting the foregoing, you acknowledge and agree that, as between you and Buygoon, the Buygoon functionality (sharing of cashback with your downstream) name, fonts, color scheme, logos (including the Buygoon turtle mascot representations) and overall look and feel of the website, are the exclusive property of Buygoon. The Service may permit the use of the Buygoon name and/or trademarks as part of social networks, emails and/or other methods. Any such use is only permitted hereby under a specific, limited and revocable trademark license by Buygoon to you only for such permitted use of the Service.</p>
          <p>Please note carefully, that the Buygoon method of calculating and sharing cashback among multiple layers of its membership are subject to multiple patents pending. You represent, warrant, covenant and expressly acknowledge and agree you will during the Term of this Agreement and for a period of ten (10) years thereafter not copy and/or participate in the implementation of such business method (or copy and/or participate in the implementation of a business method which is confusingly similar to the Buygoon business method) nor reverse engineer, disassemble, decompile or otherwise derive source code of the Buygoon website or its applications or services for any reason.</p>
          <p>Buygoon does not, by this Agreement or otherwise, permit you to rent, sell or sublicense any intellectual property of Buygoon or any of its retail partners. You acknowledge and agree that remedies at law would be inadequate to protect Buygoon against any actual or threatened breach of this Agreement by you. Accordingly, you agree to the granting of injunctive relief or other equitable relief in favor of Buygoon (without posting a bond or proof of actual damages, or showing that money damages are not an adequate remedy, if same would be otherwise required) in the event of any such actual or threatened breach, in addition to any remedies to which Buygoon may be entitled at law. You further agree to pay all costs and expenses of Buygoon (including attorney’s fees) in its seeking of an injunction and successfully enforcing this Agreement. Buygoon software, underlying information and technology may not be exported or re-exported into any country to which the U.S. has embargoed goods or to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Commerce Department's Table of Deny Orders. You represent, warrant, covenant and agree that you are not located in, under the control of, or a national or resident of any such country or on any such list, and that you will otherwise comply with all applicable export control laws.</p>
          <p>You will not attempt to, or assist another person or entity to, reverse-engineer, decompile, disassemble, or otherwise tamper with any of the security measures related to the Service for any reason whatsoever and you will not modify any software utilized by or in the Service in any manner or form, or to use modified versions of such software, for any purposes including without limit for obtaining unauthorized access to the Service. You will not undertake any action that will burden the capacity of the Service’s computer network. VIOLATION OF THIS AGREEMENT AND/OR ANY APPLICABLE LAWS MAY RESULT IN MONETARY DAMAGES AND CRIMINAL SANCTIONS.</p>
          <p>The Service may, but is not obligated to, offer interactive features that may allow you to, among other things, submit or post information, materials or links to third party content on areas of the Service accessible and viewable by other users of the Service and the public. You represent, warrant and agree that any use by you of such features, including any information, materials or links submitted or posted by you, shall be your sole and complete responsibility, shall not infringe or violate the law or any rights of any other entity, contribute to or encourage infringing or otherwise unlawful conduct, or otherwise be (in our sole discretion) obscene, objectionable or in poor taste, and that you have in advance obtained all necessary rights, licenses or clearances in relation to such information, materials and links and hereby grant Buygoon a perpetual, irrevocable, worldwide, royalty-free, non-exclusive license (or sub-license as the case may be) to use such materials as part of the Service, without any compensation or obligation to you or any other entity whatsoever. You further agree to provide accurate and complete information in connection with your submission or posting of any information or materials on the Service. Buygoon reserves the right not to post or publish any materials, and to delete, remove or edit any material, at any time in its sole discretion without notice or liability to any entity whatsoever, without limit on any other rights or remedies available to Buygoon in relation thereto.</p>
          
          <hr/>
          <h4>10. Disclaimer Of Warranties</h4>
          <p>BUYGOON MAKES NO, AND HEREBY EXPRESSLY AND SPECIFICALLY DISCLAIMS ANY AND ALL, REPRESENTATIONS, WARRANTIES, OR CONDITIONS, EXPRESS OR IMPLIED, REGARDING ANY MATTER SUBJECT TO THIS AGREEMENT, PAST OR PRESENT, UNDER AND ALL LEGAL THEORIES, WHETHER IN TORT, CONTRACT, STATUTORY OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR COURSE OF PERFORMANCE, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TITLE, QUANTITY OR TYPE OF USERS OR TRAFFIC.</p>
          <p>BUYGOON MAKES NO WARRANTY THAT OPERATION OF THE BUYGOON WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE AND BUYGOON MAKES NO WARRANTY REGARDING ANY GOODS OR SERVICES PURCHASED OR OBTAINED THROUGH BUYGOONS RETAIL PARTNERS’ WEBSITES OR ANY TRANSACTIONS ENTERED INTO BY WAY OF THE BUYGOON WEBSITE. THE BUYGOON WEBSITE AND SERVICES ARE PROVIDED "AS IS" WITH NO WARRANTY. BUYGOON MAY AT ITS ELECTION, AT ANY TIME, AND FROM TIME TO TIME, CHANGE OR DISCONTINUE ANY PART OF THE BUYGOON WEBSITE AND RELATED SERVICES, SUCH THAT YOU MAY NO LONGER BE ABLE TO USE SAME OR USE IT TO THE SAME EXTENT AS PRIOR TO SUCH CHANGE OR DISCONTINUATION. BUYGOON WAIVES ANY AND ALL LIABILITY FOR ANY DAMAGES CAUSED BY YOUR ACCESS TO, OR INABILITY TO ACCESS, THE BUYGOON WEBSITE AND RELATED SERVICES AND THIRD PARTY WEBSITES. YOU AGREE TO INDEMNIFY AND HOLD BUYGOON, ITS PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS AND EMPLOYEES, HARMLESS FROM ANY CLAIM OR DEMAND, INCLUDING REASONABLE ATTORNEY'S FEES, MADE BY ANY PARTY DUE TO OR ARISING OUT OF YOUR USE OF THE BUYGOON WEBSITE AND RELATED SERVICES AND THIRD PARTY WEBSITES.</p>
          <p>BUYGOON MAKES NO WARRANTY RELATING TO THE AMOUNT OF CASHBACK YOU MAY EARN FROM BUYGOON.</p>
          <p>BUYGOON INC. IS NOT A "GET RICH QUICK" OR "PYRAMID" SCHEME. YOUR PARTICIPATION IS ENTIRELY FREE AND VOLUNTARY. DO NOT EVER CHARGE OR PAY ANY OTHER PERSON TO JOIN BUYGOON. IT IS INTENDED TO BOTH SAVE YOU MONEY WHILE YOU SHOP AND EARN YOU MONEY WHEN YOUR DOWNSTREAM NETWORK SHOPS ONLINE THROUGH OUR WEBSITE. HOWEVER, WE OFFER NO GUARANTEE AS TO HOW MUCH MONEY YOU MAY EARN USING OUR WEBSITE. EACH BUYGOON MEMBER’S LEVEL OF SUCCESS CAN DEPEND UPON AND VARY WITH ANY NUMBER OF FACTORS, INCLUDING THINGS LIKE, YOUR BUSINESS SAVVY, MOTIVATION, SOCIAL CONNECTIONS, SKILL, KNOWLEDGE, ABILITY, EXPERIENCE AND GOALS, AS WELL AS, GENERAL RETAIL MARKET AND ECONOMIC CONDITIONS. ANY FORWARD-LOOKING OR FINANCIAL STATEMENTS MADE BY US OR ON OUR BEHALF ARE MERELY AND STRICTLY FOR POTENTIAL PERFORMANCE, AND DO NOT REPRESENT ANY PROMISE, STANDARD, ACTUAL OR TYPICAL PERFORMANCE; SUCH STATEMENTS ARE ONLY AN OPINION. NO FUTURE GUARANTEES CAN BE MADE THAT YOU WILL ACHIEVE ANY RESULTS OR MAKE ANY MONEY FROM OUR WEBSITE, AND WE OFFER NO PROFESSIONAL LEGAL OR FINANCIAL ADVICE. EACH BUYGOON MEMBER IS RESPONSIBLE AND ACCOUNTABLE FOR THEIR OWN DECISIONS AND ACTIONS, AND BY YOUR USE OF THE WEBSITE, YOU AGREE NOT TO ATTEMPT TO HOLD US LIABLE FOR ANY SUCH DECISIONS OR ACTIONS, AT ANY TIME, UNDER ANY CIRCUMSTANCE.</p>
          
          <hr/>
          <h4>11. Limitation Of Liability</h4>
          <p>IN NO EVENT SHALL BUYGGON BE LIABLE FOR ANY DAMAGES OF ANY KIND (INCLUDING WITHOUT LIMITATION, LOSS OF DATA, OR EQUIPMENT DOWNTIME), EVEN IF BUYGOON KNOWS, SHOULD KNOW, OR WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL BUYGOON’S TOTAL AGGREGATE LIABILITY FOR CLAIMS ARISING UNDER THIS AGREEMENT EXCEED THE MAXIMUM AMOUNT OF CASHBACK EARNED BY YOU HEREUNDER IN A SINGLE GIVEN CALENDAR MONTH, TO A MAXIMUM TOTAL AGGREGATE OF US $100. IN NO CASE SHALL BUYGOON'S DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, LICENSEES AND/OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIM OF DAMAGE WHATSOEVER, WHETHER DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL, RELATING IN ANY WAY TO YOUR USE OF ANY OF THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS, EVEN IF ADVISED OF THEIR POSSIBILITY, AND YOU AGREE YOU WILL NOT COMMENCE, AND WILL NOT PARTICIPATE WITH ANY OTHERS WHO MAY COMMENCE ON YOUR BEHALF, ANY SUCH CLAIM, ACTION OR OTHER PROCEEDING. YOU AGREE ALL OF THE FOREGOING IN THIS PARAGRAPH IS REASONABLE AND FAIR AND FUNDAMENTAL TO THE BARGAIN MADE IN THIS AGREEMENT.</p>
          
          <hr/>
          <h4>12. General Provisions</h4>
          <p>This Agreement will inure to the benefit of and be binding upon your heirs, executors, administrators, and representatives. This Agreement is personal to you, and accordingly, you are not entitled to sell, assign, transfer, license and otherwise use or dispose of any of your rights or obligations under this Agreement in whole or in part to any entity at any time. Buygoon made assign, sell and/or transfer this Agreement, in whole or in part, at any time and from time to time, at its sole discretion and without notice to you.</p>
          <p>You agree Buygoon is entitled, without any liability to you or any party claiming through you or on your behalf, to or to not (without any limit on its rights and/or remedies available), cooperate with any legal process and/or a third party claim relating to your use of the Buygoon website and/or services, and to take any steps it believes are necessary or appropriate (including without limit the disclosure of any or all your personal data and/or account information to any law enforcement authorities, government officials and/or third parties) to enforce and/or verify compliance with any part of this Agreement.</p>
          <p>This Agreement constitutes the entire agreement between the parties hereto with respect to the subject matter hereof and cancels and supersedes any prior understandings and agreements between the parties hereto with respect thereto. There are no representations, warranties, covenants, terms, conditions, undertakings or collateral agreements, express, implied or statutory, between the parties other than as expressly set forth in this Agreement. Any failure to claim against you, or waiver by Buygoon of, any breach by you of any provision of this Agreement will be limited to the specific breach in the given instance, and not in any way be held as against Buygoon’s interests or remedies in any other instance.</p>
          <p>The parties hereto enter into this Agreement as independent contractors. Nothing herein contained will be construed as in any way constituting an employment, a partnership or a joint venture by the parties hereto, or be construed to evidence the intention of the parties to constitute such a relationship.</p>
          <p>This Agreement will be governed by and construed in accordance with the laws of the Province of Ontario and the Canadian Federal laws applicable therein, without regard to conflicts of laws rules or the U.N. Convention on Contracts for the International Sale of Goods. For the purpose of all legal proceedings this Agreement will be deemed to have been performed in the Province of Ontario. The foregoing shall apply notwithstanding your physical location, jurisdiction, residence or domicile. Notwithstanding the foregoing in this Paragraph, you agree to comply with ALL governmental (whether federal, provincial, state, municipal or otherwise) laws, statutes, ordinances and regulations that may apply to your use of the Service.</p>
          <p>Any claim, dispute or controversy (whether in contract or tort, pursuant to statute or regulation, or otherwise, and whether pre-existing, present or future), including without limitation, arising out of or relating to (i) this Agreement, (ii) the Buygoon website or service, (iii) oral or written statements, or advertisements or promotions relating to this Agreement or to a product or service, or (iv) the relationships which result from this Agreement, including without limit relationships with third parties who are not parties to this Agreement (each, a "Claim"), will be referred to and finally settled by private and confidential binding arbitration before a single arbitrator chosen by Buygoon, within 100 kilometres of City Hall in Toronto, Ontario in English and governed by Ontario law pursuant to the Arbitration Act, 1991 (Ontario), as amended, replaced or re-enacted from time to time. The arbitrator shall be a person who is legally trained, and who has experience in the information technology field in Canada, and is independent of either party. If the Claim is initiated by you, the arbitration cost shall be borne by you. If the Claim is initiated by Buygoon, the arbitration cost shall be borne jointly. You agree that any judgment of the arbitrator against you shall be fully registerable and enforceable against you in a court in Ontario, or in your jurisdiction of residence, location or domicile, at Buygoon’s election, and you irrevocably attorn to the jurisdiction of such court.</p>
          <p>You hereby waive any right you may have to, and agree you will not, commence or participate in any class action against Buygoon related to any claims, actions or demands and, where applicable, you hereby agree to opt out of any class proceeding against us otherwise commenced. In the event that the foregoing in this paragraph is prohibited by any applicable law, you nonetheless agree that Buygoon's total and only remedy to you as a member of such class action will not exceed one Canadian dollar. You agree that the foregoing in this paragraph is a material and fundamental representation and condition for entering into this Agreement.</p>
          <p>Some jurisdictions may not allow the use of compulsory arbitration or the waiver of rights to participate in a class action. If applicable law renders clauses requiring mandatory arbitration or the exclusion of the right to participate in a class action (or either one of them) void, the provisions of this section with respect to either remedy considered separately shall be subject to severance in accordance with the provision below in this Agreement. Notwithstanding the foregoing, in the event of a court proceeding, you irrevocably waive your right to a trial by jury; all such proceedings will be in front of a judge or panel of judges, as the case may be.</p>
          <p>You agree Buygoon is entitled, without any liability to you or any party claiming through you or on your behalf, to or to not (without any limit on its rights and/or remedies available), cooperate with any legal process and/or a third party claim relating to your use of the Service, and to take any steps it believes are necessary or appropriate (including without limit the disclosure of any or all your personal data and/or account information to any law enforcement authorities, government officials and/or third parties) to enforce and/or verify compliance with any part of this Agreement.  You are not entitled to any proceeds to which Buygoon is or becomes entitled by virtue of winning a legal claim.</p>
          <p>The invalidity of any provision herein will apply to that provision only such that the remainder of the Agreement will be valid and binding to the full extent possible under the law.</p>
          <p>If you have a Claim you must give notice to Buygoon Inc., 3-35 Stone Church Road, Suite #183, Hamilton, Ontario, Canada, L9K 1S5, with copy concurrently to <a href="mailto:admin@buygoon.com">admin@buygoon.com</a> and fax: 416-362-4443, Attention: General Counsel. If we have a Claim we may give you notice to either of your email address or your last known mailing address we have on record.</p>
        </Col>
      </Row>

    </Layout>
  )
}

export default TermsAndConditions