import React from 'react'
import { Row, Col, Container } from 'react-grid-system'
import cx from 'classnames'

import Bullhorn from 'images/bullhorn.png'
import Logo from 'images/logo_sm.png'
import Paypal from 'images/paypal.png'
import RainingMoney from 'images/raining-money.png'
import ThirtySecondTimer from 'images/thirty-second-timer.png'

import layoutStyles from '../Layout/Layout.module.scss'
import styles from './MakeMoney.module.scss'

import Button from '../Button'

const MakeMoney = ({ currentUser }) => {
  return (
    <div className={layoutStyles.layout}>
      <Container>
        <Row>
          <Col>
            <div className={styles.pageHeader}>
              <h1 className={styles.keyTwoContent}>Free. Simple. Passive Income.</h1>
            </div>
            
            <div className={cx(styles.makeMoneyHeader, styles.paddingContainerFirstRow)}>
              <div className={cx(styles.makeMoneyHeaderContent, styles.verticalContent)}>
                <h2 className={styles.heading}>Want more money?
                </h2>

                <p className={styles.content}>
                  <span className={styles.content}>Buygoon is the simplest platform ever invented </span>
                  <span className={styles.content}>for <span className={styles.keyTwoContent}>you</span> to <span className={styles.keyTwoContent}>get paid </span></span>
                  <span className={styles.content}>when your social network shops.</span>
                </p>
                
                {!currentUser && (
                  <>
                    <br />
                    <span className={styles.content}>Join for <span className={styles.keyTwoContent}>free</span> now</span>
                    <span className={styles.content}>to start <span className={styles.keyTwoContent}>making money!</span></span>
                    <div className={styles.button}>
                      <Button variant="primary" href="/account/sign-up">Join Now for Free</Button>
                    </div>
                  </>
                )}

                {currentUser && (
                  <div className={styles.button}>
                    <Button variant="primary" href="/account/my-referral-codes">Grow My Network</Button>
                  </div>
                )}
              </div>

              <div className={styles.horizontalCenter}>
                <video className={styles.growMyNetworkVideo} autoPlay muted playsInline loop>
                  <source src="https://s3.amazonaws.com/buygoon.com/_static-assets/videos/buygoon_animated_4_rings_white_1080p_no_audio.mp4" type="video/mp4" />
                  
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={cx(styles.fullWidthContainer, styles.paddingContainer)}>
        <Container>
          <Row>
            <Col>
              <div className={styles.earnMoney}>
                <div className={styles.verticalContent}>
                  <h2 className={cx(styles.heading)}>For real... <span className={styles.keyTwoContent}>EARN MONEY</span> when your network shops online at popular stores!</h2>
                  <br />
                  <span className={styles.content}>
                    It&apos;s really NOT too good to be true! We pay you when your social network shops at our partner stores.
                  </span>
                  <br />
                  <span className={styles.content}>
                    <span className={styles.keyTwoContent}>YOU</span> get paid when your friends, and their friends, and their friends, and their friends shop online at our partner stores.
                  </span>
                </div>

                <div className={styles.staticLogosContainer}>
                  <div className={styles.staticLogosRow}>
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/apple-logo.png" />
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/target-logo.png" />
                  </div>
                  <div className={styles.staticLogosRow}>
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/nike-logo.png" />
                    <img className={styles.staticLogo} src={Logo} />
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/walmart-logo.jpg" />
                  </div>
                  <div className={styles.staticLogosRow}>
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/home-depot-logo.png" />
                    <img className={styles.staticLogo} src="https://s3.amazonaws.com/buygoon.com/_static-assets/registration_sessions-static_logo/best-buy-logo.png" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className={cx(styles.howItWorksHeading, styles.paddingContainerHowItWorksStep)}>
              <h2 className={cx(styles.heading, styles.horizontalCenter)}>
                How it works
              </h2>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className={cx(styles.howItWorksStep, styles.howItWorksStepOne, styles.paddingContainerFirstRow)}>
              <div className={cx(styles.howItWorksStepContent, styles.verticalContent)}>
                <div className={styles.howItWorksStepTitle}>
                  {!currentUser && (
                    <h3>Sign Up</h3>
                  )}

                  {currentUser && (
                    <h3>Sign In</h3>
                  )}                  
                </div>
                
                <div className={styles.howItWorksStepDescription}>
                  <p className={styles.content}>It&apos;s quick, easy and always free to use.</p>
                </div>
              </div>
              
              <div className={styles.horizontalCenter}>
                <img src={ThirtySecondTimer} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={cx(styles.fullWidthContainer, styles.paddingContainer)}>
        <Container>
          <Row>
            <Col>
              <div className={cx(styles.howItWorksStep, styles.howItWorksStepTwo)}>
                <div className={styles.horizontalCenter}>
                  <img src={Bullhorn} />
                </div>

                <div className={styles.howItWorksStepContent}>
                  <div className={styles.howItWorksStepTitle}>
                    <h3>Invite your friends & followers to join Buygoon</h3>
                  </div>
                  
                  <div className={styles.howItWorksStepDescription}>
                    <p className={styles.content}>Share your personalized referral link so all your friends and followers will be automatically added to your network.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className={cx(styles.howItWorksStep, styles.howItWorksStepThree, styles.buygoonNetwork, styles.paddingContainer)}>
              <div className={cx(styles.howItWorksStepContent, styles.verticalContent)}>
                <div className={styles.howItWorksStepTitle}>
                  <h3>Your Buygoon Network grows automatically</h3>
                </div>
                
                <div className={styles.howItWorksStepDescription}>
                  <p className={styles.content}>When your followers join, they can invite their friends, and so on and so on. That creates your Buygoon Downstream Network which can expand to 4 Ripples and will be made of your sign-ups, their sign-ups, their sign-ups and their sign-ups.</p>
                </div>
                <div className={styles.howItWorksStepDescription}>
                  <p className={cx(styles.content, styles.keyTwoContent)}>Your Downstream Network could contain 100&apos;s, 1,000&apos;s or more people, all earning you money!</p>
                </div>
              </div>
              
              <div className={styles.horizontalCenter}>
                <video autoPlay muted playsInline loop height="350">
                  <source src="https://s3.amazonaws.com/buygoon.com/_static-assets/videos/bg_animated_logo.mp4" type="video/mp4" />
                
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={cx(styles.fullWidthContainer, styles.paddingContainer)}>
        <Container>
          <Row>
            <Col>
              <div className={cx(styles.howItWorksStep, styles.howItWorksStepFour)}>
                <div className={styles.horizontalCenter}>
                  <img className={styles.rainingMoneyImage} src={RainingMoney} />
                </div>
                
                <div className={cx(styles.howItWorksStepContent, styles.verticalContent)}>
                  <div className={styles.howItWorksStepTitle}>
                    <h3>Make Money! You get paid every time someone in your Downstream Network earns cash back</h3>
                  </div>
                  
                  <div className={styles.howItWorksStepDescription}>
                    <p className={styles.content}>Each time someone in your Downstream Network gets cash back, you earn a cash bonus equal to 10% of their cash back.</p>
                    <p className={styles.content}>That&apos;s right, even if you don&apos;t shop, you can get paid...even while you sleep! That&apos;s real passive income and it may be the <span className={styles.keyTwoContent}>easiest side hustle</span> ever.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className={cx(styles.howItWorksStep, styles.howItWorksStepFive, styles.paddingContainer)}>
              <div className={cx(styles.howItWorksStepContent, styles.verticalContent)}>
                <div className={styles.howItWorksStepTitle}>
                  <h3>Collect Your Money</h3>
                </div>
                
                <div className={styles.howItWorksStepDescription}>
                  <p className={styles.content}>Withdraw <span className={styles.keyTwoContent}>your cash</span> from your Buygoon account through PayPal.</p>
                </div>
              </div>
              
              <div className={styles.horizontalCenter}>
                <img className={styles.paypalImage} src={Paypal} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            {!currentUser && (
              <div className={styles.button}>
                <Button variant="primary" href="/account/sign-up">Join Now to Make Money</Button>
              </div>
            )}

            {currentUser && (
              <div className={styles.button}>
                <Button variant="primary" href="/account/my-referral-codes">Grow My Network</Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MakeMoney