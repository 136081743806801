import React from 'react'
import cx from 'classnames'
import { Hidden, Visible } from 'react-grid-system'

import styles from './AccountSidebar.module.scss'

import Button from '../Button'

const AccountSidebar = ({ user, userCurrency, networkCount, totalAmountEarned }) => {
  const url = window.location.pathname

  const dollars = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: userCurrency,
  })

  return (
    <div className={styles.sidebar}>
      <Hidden xs>
        <div className={styles.userInfo}>
          <h3 className={styles.header}>Welcome back,</h3>

          <h3 className={cx(styles.content, styles.name)}>{user.nickname}</h3>
        </div>
      </Hidden>

      <div className={styles.sectionWrapper}>
        <div className={styles.section}>
          <h3 className={styles.header}>My Total Network</h3>
          <h3 className={styles.content}>{networkCount}</h3>
        </div>
        
        {!(url === '/account/my-referral-codes') && (
           <>            
            <br />
            <div className={styles.button}>
                <Button variant="primary" href="/account/my-referral-codes">
                  <Visible xs sm>
                    Grow Network
                  </Visible>
                  <Hidden xs sm>
                    Grow My Network
                  </Hidden>
                </Button>
            </div>
          </>
        )}
      </div>

      <div className={cx(styles.section, styles.lastSection)}>
        <h3 className={styles.header}>My Lifetime</h3>
        <h3 className={styles.header}>Cash Earned</h3>

        <h3 className={styles.content}>{`${dollars.format(totalAmountEarned)}`}</h3>
      </div>

      <Visible lg xl xxl>
        <div className={styles.navigation}>
          <div className={cx(styles.navigationItem, { [styles.selected]: url === '/account/my-cash' })}>
            <a href="/account/my-cash" className={styles.navigationHeading}>
              My Cash
            </a>
          </div>

          <div className={cx(styles.navigationItem, { [styles.selected]: url === '/account/my-purchases' })}>
            <a href="/account/my-purchases" className={styles.navigationHeading}>
              My Purchases
            </a>
          </div>
          
          <div className={cx(styles.navigationItem, { [styles.selected]: url === '/account/my-network' })}>
            <a href="/account/my-network" className={styles.navigationHeading}>
              My Network
            </a>
          </div>

          <div className={cx(styles.navigationItem, { [styles.selected]: url === '/account/my-referral-codes' })}>
            <a href="/account/my-referral-codes" className={styles.navigationHeading}>
              My Referral Codes
            </a>
          </div>
          
          <div className={cx(styles.navigationItem, { [styles.selected]: url === '/account/my-profile' })}>
            <a href="/account/my-profile" className={styles.navigationHeading}>
              My Profile
            </a>
          </div>
        </div>
      </Visible>
    </div>
  )
}

export default AccountSidebar