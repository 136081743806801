import React from 'react'
import { Col, Row, Visible } from 'react-grid-system'

import styles from './Categories.module.scss'

import Layout from '../Layout'

const Categories = ({ categories }) => {
  return (
    <Layout>
      <Row>
        <Visible lg xl xxl>
          <Col lg={4}>
            <div className={styles.categoriesMenu}>
              <h3 className={styles.categoriesTitle}>Categories</h3>

              <ul className={styles.categoriesMenuList}>
                {categories.map((category) => {
                  return (
                    <li key={category.name} className={styles.categoriesMenuListItem}>
                      <a href={`/categories/${category.slug}`}>
                        {category.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </Col>
        </Visible>

        <Col lg={8}>
          <div className={styles.categoriesList}>
            {categories.map((category) => {
              return (
                <a key={category.name} href={`/categories/${category.slug}`}>
                  <div className={styles.category}>
                    <div className={styles.categoryImage}>
                      <img src={`${category.thumbnail_url || 'https://picsum.photos/220/80'}`} width="160" height="67" />
                    </div>
                  </div>

                  <div className={styles.cardContainer}>
                    <div className={styles.categoryName}>
                      {category.name}
                    </div>
                  </div>
                </a>
              )
            })}
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Categories