import React from 'react'
import cx from 'classnames'

import styles from './TopCategories.module.scss'

const TopCategoriesListItem = ({ category }) => {
  return (
    <a className={styles.topCategoriesListItemLink} href={category ? `/categories/${category.slug}` : '/categories'}>
      <div className={cx(styles.topCategoriesListItem, { [styles.seeAllCategoriesListItem]: !category })}>
        {category && (
          <div className={styles.categoryImage}>
            <img src={`${category.thumbnail_url || 'https://picsum.photos/220/80'}`} width="160" height="67" />
          </div>
        )}
        
        {!category && (
          <div className={styles.seeAllCategories}>
            <h3>See All Categories</h3>
          </div>
        )}
      </div>

      {category && (
        <div className={styles.categoryNameContainer}>
          <div className={styles.categoryName}>
            {category.name}
          </div>
        </div>
      )}
    </a>
  )
}

export default TopCategoriesListItem