import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'

export const AUTO_CLOSE_DELAY = 3000

const FlashMessage = ({ messageType, message }) => {
  const showFlashMessage = () => {
    switch(messageType) {
      case 'success':
        toast.success(message, { autoClose: AUTO_CLOSE_DELAY })
        break
      case 'error':
        toast.error(message)
        break
      case 'alert':
        toast.warn(message, { autoClose: AUTO_CLOSE_DELAY })
        break
      case 'notice':
        toast.info(message, { autoClose: AUTO_CLOSE_DELAY })
        break
      default:
        toast.info(message)
    }
  }

  useEffect(() => {
    showFlashMessage()
  }, [messageType, message])

  return (
    <ToastContainer
      theme="colored"
      position="top-right"
      autoClose={false}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  )
}

export default FlashMessage