import React, { useState } from 'react'
import { Row, Col } from 'react-grid-system'
import cx from 'classnames'

import styles from './Stores.module.scss'

import Button from '../Button'
import FlashMessage from '../FlashMessage'
import Layout from '../Layout'

const Stores = ({ alphabets, stores: ALL_STORES, storesDetails, flashMessages }) => {
  const [filteredStores, setFilteredStores] = useState(ALL_STORES)
  const [filter, setFilter] = useState('all')
  const isNumeric = (name) => /^-?\d+$/.test(name[0])
  const filterStoresByNumbers = ALL_STORES.filter(({ name }) =>  isNumeric(name))
  const findStoreDetailsBySlug = (slug) => storesDetails.find((store) => store.slug === slug)

  return (
    <Layout>
      {flashMessages.length > 0 && flashMessages.map((flashMessage) => {
        const { message_type, message } = flashMessage

        return (
          <FlashMessage
            key={message}
            messageType={message_type}
            message={message}
          />
        )
      })}

      <Row>
        <Col md={12}>
          <div className={styles.header}>
            <div className={styles.heading}>
              <h1>All Stores</h1>
            </div>

            <div className={styles.alphabetContainer}>
              <div
                className={cx(styles.letter, { [styles.selected]: filter === 'all' })}
                onClick={() => {
                  setFilter('all')
                  setFilteredStores(ALL_STORES)
                }}
              >
                All
              </div>

              {alphabets.map((letter, index) => {
                if (letter === '#') return

                return (
                  <div
                    key={`${letter}-${index}`}
                    className={cx(styles.letter, { [styles.selected]: filter === letter })}
                    onClick={() => {
                      const filteredRetailers = ALL_STORES.filter(({ name }) => name.startsWith(letter))
                      setFilter(letter)
                      setFilteredStores(filteredRetailers)
                    }}
                  >
                    {letter}
                  </div>
                )
              })}

              {filterStoresByNumbers.length > 0 && (
                <div
                  className={cx(styles.letter, { [styles.selected]: filter === '#' })}
                  onClick={() => {
                    setFilter('#')
                    setFilteredStores(filterStoresByNumbers)
                  }}
                >
                  0 - 9
                </div>
              )}
            </div>
          </div>

          <div className={styles.retailers}>
            {filteredStores.map((retailer) => {
              const storeDetails = findStoreDetailsBySlug(retailer.slug)

              return (
                <a key={retailer.slug} href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} className={styles.retailerLink} target="_blank">
                  <div className={styles.retailerCard}>
                    <div className={styles.retailer}>
                      <div className={styles.brand}>
                        <img src={`${retailer.logo_url || 'https://picsum.photos/100/47'}`} className={styles.logo} />
                      </div>

                      <div className={styles.cardContainer}>
                        {storeDetails && (
                          <>
                            <div className={styles.button}>
                              <object>
                                <Button variant="secondary" href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} target="_blank">
                                  Shop Now
                                </Button>
                                
                                {storeDetails && storeDetails.home_page_link_info && storeDetails.home_page_link_info.impression_pixel && (
                                  <img src={storeDetails.home_page_link_info.impression_pixel} />
                                )}
                              </object>
                            </div>
                            
                            <div className={styles.cashBack}>
                              {storeDetails.display_commission_string_html}
                            </div>

                            <a href={`/stores/${retailer.slug}`} className={styles.seeInfo}>
                              More Details & Coupons
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              )
            })}
          </div>

          <div className={styles.header}>
            <div className={styles.moreStoresContainer}>
              New Stores are added regularly. Check back soon for your favorite store!
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Stores