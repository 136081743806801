import React from 'react'
import cx from 'classnames'

import styles from './UserMenu.module.scss'

const UserMenuLink = ({ href, children }) => {
  const url = window.location.pathname

  return (
    <li>
      <a href={href} className={cx(styles.userMenuLink, { [styles.selected]: href === url })}>
        {children}
      </a>
    </li>
  )
}

export default UserMenuLink