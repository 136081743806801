import React from 'react'
import cx from 'classnames'

import { DialogContent, DialogOverlay } from '@reach/dialog'

import '@reach/dialog/styles.css'
import styles from './Modal.module.scss'

const Modal = ({
  children,
  modalTitle,
  showModal,
  onDismiss,
  className,
}) => {
  return (
    <DialogOverlay
      isOpen={showModal}
      onDismiss={onDismiss}
      className={styles.dialogOverlay}
    >
      <DialogContent
        className={cx(styles.modal, className)}
        aria-label={modalTitle}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export default Modal
