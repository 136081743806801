import React from 'react'
import { Row, Col } from 'react-grid-system'

import styles from './SearchResults.module.scss'

import Button from '../Button'
import Layout from '../Layout'

const SearchResults = ({ stores, storesDetails }) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const { query } = params
  const findStoreDetailsBySlug = (slug) => storesDetails.find((store) => store.slug === slug)
                  
  return (
    <Layout>
      <Row>
        <Col>
          {stores && stores.length > 0 && (
            <>
              <h1 className={styles.heading}>{`Search results for '${query}'`}</h1>

              <div className={styles.retailers}>
                {stores.map((retailer) => {
                  const storeDetails = findStoreDetailsBySlug(retailer.slug)

                  return (
                    <a key={retailer.slug} href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} className={styles.retailerLink} target="_blank">
                      <div className={styles.retailerCard}>
                        <div className={styles.retailer}>
                          <div className={styles.brand}>
                            <img src={`${retailer.logo_url || 'https://picsum.photos/100/47'}`} className={styles.logo} />
                          </div>

                          <div className={styles.cardContainer}>
                            {storeDetails && (
                              <>
                                <div className={styles.button}>
                                  <object>
                                    <Button variant="secondary" href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} target="_blank">
                                      Shop Now
                                    </Button>
                                    
                                    {storeDetails && storeDetails.home_page_link_info && storeDetails.home_page_link_info.impression_pixel && (
                                      <img src={storeDetails.home_page_link_info.impression_pixel} />
                                    )}
                                  </object>
                                </div>
                                
                                <div className={styles.cashBack}>
                                  {storeDetails.display_commission_string_html}
                                </div>

                                <a href={`/stores/${retailer.slug}`} className={styles.seeInfo}>
                                  More Details & Coupons
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  )
                })}
              </div>
            </>
          )}

          {(!stores || stores.length === 0) && (
            <div className={styles.noResultsFound}>
              <h1>{`No results found for '${query}'`}</h1>
            </div>
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export default SearchResults