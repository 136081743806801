import React from 'react'

import styles from './TopCategories.module.scss'

import TopCategoriesListItem from './ListItem'

const TopCategories = ({ categories }) => {
  return (
    <div className={styles.topCategories}>
      <div className={styles.header}>
        <h2>Explore Top Categories</h2>
      </div>

      <div className={styles.topCategoriesContainer}>
        {categories.map((category) => {
          return (
            <TopCategoriesListItem
              key={category.id}
              category={category}
            />
          )
        })}
      
        <TopCategoriesListItem />
      </div>
    </div>
  )
}

export default TopCategories