import React from 'react'

import styles from './CategoriesMenu.module.scss'

import Menu from '../Menu'

import CategoriesMenuButton from './Button'
import CategoriesMenuLink from './Link'

const CategoriesMenu = ({ categories }) => {
  return (
    <Menu open="left" button={CategoriesMenuButton}>
      {() => (
        <div className={styles.categoriesMenu}>
          <ul>
            <CategoriesMenuLink href="/categories">
              <span className={styles.showAll}>
                Show All Categories
              </span>
            </CategoriesMenuLink>

            {categories.map(({ name, slug }) => (
              <CategoriesMenuLink key={slug} slug={slug} href={`/categories/${slug}`}>
                {name}
              </CategoriesMenuLink>
            ))}
          </ul>
        </div>
      )}
    </Menu>
  )
}

export default CategoriesMenu
