import React from 'react'
import cx from 'classnames'

import styles from './FeaturedStores.module.scss'

const FeaturedStoresListItem = ({ linkDetail }) => {
  if (!linkDetail) {
    return (
      <a href="/stores" className={styles.featuredStoresListItem}>
        <div className={styles.container}>
          <h3>See All Stores</h3>
        </div>
      </a>
    )
  }
  else {
    return (
      <a className={cx({ [styles.storeLink]: linkDetail })} href={linkDetail.home_page_link || `/stores/${linkDetail.store_slug}`} target="_blank">
        <div className={styles.featuredStoresListItem}>
          <div className={styles.container}>
            <div className={styles.image}>
              <img src={`${linkDetail && linkDetail.logo_url ? linkDetail.logo_url : 'https://picsum.photos/240/100'}`} />
            </div>
          </div>
        </div>
  
        {linkDetail && (
          <div className={styles.cashbackContainer}>
            <svg xmlns="https://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
  
            <h3 className={styles.cashback}>{linkDetail.display_commission_string_html}</h3>
  
            {linkDetail && linkDetail.home_page_link_info && linkDetail.home_page_link_info.impression_pixel && (
              <img src={linkDetail.home_page_link_info.impression_pixel} />
            )}
          </div>
        )}
      </a>
    )
  }
}

export default FeaturedStoresListItem