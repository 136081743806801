import React from 'react'
import { setConfiguration } from 'react-grid-system'

import 'react-toastify/dist/ReactToastify.css'

setConfiguration({
  containerWidths: [540, 740, 960, 1170, 1170],
})

const App = () => (<></>)

export default App