import React from 'react'
import cx from 'classnames'
import { Row, Col, Visible } from 'react-grid-system'

import styles from './SaveMoneyMakeMoney.module.scss'

import Button from '../Button'
import Layout from '../Layout'

const SaveMoneyMakeMoney = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <div className={styles.header}>
            <h1>Welcome to Buygoon!</h1>
          </div>
        </Col>
      </Row>
      
      <Row>
        <Col>
          <div className={styles.container}>
            <a href="/save-money" className={cx(styles.bgContainer, styles.saveMoneyContainer)}>
              <div className={styles.content}>
                <div className={styles.contentHeader}>
                  <h2>SAVE MONEY</h2>
                  <h3>Spend Less. Get More.</h3>

                  <Visible xs sm>
                    <div className={styles.contentFooter}>
                      <h2>with</h2>
                      <h2>Cash Back Shopping</h2>
                    </div>
                  </Visible>
                </div>

                <div className={styles.contentFooter}>
                  <Visible md lg xl xxl>
                    <h2>with</h2>
                    <h2>Cash Back Shopping</h2>
                  </Visible>

                  <div className={styles.learnMoreButtonContainer}>
                    <Button variant="primary" href="/save-money">
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            </a>

            <Visible md lg xl xxl>
              <div className={styles.bgVideoContainer}>
                <video className={styles.growMyNetworkVideo} autoPlay muted playsInline loop>
                  <source src="https://s3.amazonaws.com/buygoon.com/_static-assets/videos/bg_animated_logo.mp4" type="video/mp4" />
                  
                  Your browser does not support the video tag.
                </video>
              </div>
            </Visible>

            <a href="/make-money" className={cx(styles.bgContainer, styles.makeMoneyContainer)}>
              <div className={styles.content}>
                <div className={styles.contentHeader}>
                  <h2>MAKE MONEY</h2>
                  <h3>Free. No Catch.</h3>
                
                  <Visible xs sm>
                    <div className={styles.contentFooter}>
                      <h2>with</h2>
                      <h2>Passive Cash Bonuses</h2>
                    </div>
                  </Visible>
                </div>

                <div className={styles.contentFooter}>
                  <Visible md lg xl xxl>
                    <h2>with</h2>
                    <h2>Passive Cash Bonuses</h2>
                  </Visible>

                  <div className={styles.learnMoreButtonContainer}>
                    <Button variant="primary" href="/make-money">
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Visible xs sm>
            <div className={styles.bgVideoMobileContainer}>
              <video className={styles.growMyNetworkVideo} autoPlay muted playsInline loop>
                <source src="https://s3.amazonaws.com/buygoon.com/_static-assets/videos/bg_animated_logo.mp4" type="video/mp4" />
                
                Your browser does not support the video tag.
              </video>
            </div>
          </Visible>

          <div className={styles.joinNowButtonContainer}>
            <div className={styles.button}>
              <Button variant="primary" href="/account/sign-up">Join Now</Button>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default SaveMoneyMakeMoney