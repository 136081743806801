import React, { useState } from 'react'
import cx from 'classnames'
import { Container, Hidden, Visible } from 'react-grid-system'

import Logo from 'images/logo_sm.png'

import styles from './Header.module.scss'

import Button from '../Button'
import CategoriesMenu from '../CategoriesMenu'
import HamburgerMenu from '../HamburgerMenu'
import StoresMenu from '../StoresMenu'
import SearchInput from '../SearchInput'
import UserMenu from '../UserMenu'

const LOGO_WIDTH = 50

const Header = ({ currentUser, categories, retailers }) => {
  const url = window.location.pathname
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  return (
    <div className={styles.header}>
      <HamburgerMenu 
        currentUser={currentUser}
        url={url}
        isHamburgerMenuOpen={isHamburgerMenuOpen}
        setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
      />

      <div className={styles.primaryHeaderContainer}>
        <Container>
          <div className={styles.primaryHeader}>
            <Visible xs sm>
              <Button className={styles.openHamburgerMenuButton} variant="transparent" onClick={() => setIsHamburgerMenuOpen(true)}>
                <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </Button>
            </Visible>

            <a className={styles.brandContainer} href="/">
              <Hidden xs sm>
                <div className={styles.logo}>
                  <img width={LOGO_WIDTH} src={Logo} />
                </div>
              </Hidden>

              <div className={styles.brandName}>
                <h2>Buygoon</h2>

                <span className={styles.byline}>Make Shopping More Rewarding</span>
              </div>
            </a>

            <Hidden xs sm>
              <div className={styles.search}>
                <form method="get" action="/search">
                  <SearchInput />
                </form>
              </div>
            </Hidden>

            {!currentUser && (
              <div className={styles.nonUserLinks}>
                <a className={styles.signIn} href="/account/sign-in">Sign In</a>
                
                <Visible xs sm>
                  <a className={styles.signUp} href="/account/sign-up">Sign Up</a>
                </Visible>

                <Hidden xs sm>
                  <Button variant="primary" href="/account/sign-up">Join Now</Button>
                </Hidden>
              </div>
            )}

            {currentUser && (
              <Hidden xs sm>
                <div className={styles.userInfo}>
                  <UserMenu currentUser={currentUser} />
                </div>
              </Hidden>
            )}
          </div>

          <Visible xs sm>
            <div className={styles.search}>
              <form method="get" action="/search">
                <SearchInput />
              </form>
            </div>
          </Visible>
        </Container>
      </div>

      <Hidden xs sm>
        <div className={styles.secondaryHeader}>
          <Container>
            <ul className={styles.navigation}>
              <li className={styles.listItem}>
                <StoresMenu retailers={retailers} />
              </li>

              <li className={styles.listItem}>
                <CategoriesMenu categories={categories} />
              </li>

              <li className={cx(styles.listItem, { [styles.selected]: url === '/make-money' })}>
                <a className={cx(styles.listItemLink)} href="/make-money">
                  <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                  </svg>              

                  <span>
                    Make Money
                  </span>
                </a>
              </li>

              <li className={cx(styles.listItem, { [styles.selected]: url === '/save-money' })}>
                <a className={styles.listItemLink} href="/save-money">
                  {/* https://icons.getbootstrap.com/icons/piggy-bank/ */}
                  <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-piggy-bank" viewBox="0 0 16 16">
                    <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z"/>
                    <path fillRule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z"/>
                  </svg>

                  <span>
                    Save Money
                  </span>
                </a>
              </li>

              <li className={cx(styles.listItem, { [styles.selected]: url === '/help' })}>
                <a className={styles.listItemLink} href="/help">
                  <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <span>
                    Help
                  </span>
                </a>
              </li>
            </ul>
          </Container>
        </div>
      </Hidden>
    </div>
  )
}

export default Header