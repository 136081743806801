import React from 'react'

import styles from './HotDeals.module.scss'

// import Button from '../Button'
import HotDealsListItem from './ListItem'

const HotDeals = ({ storesHotDealsDetails }) => {
  if (storesHotDealsDetails.length <= 0) {
    return (
      null
    )
  }
  else {
    return (
      <div>
        <div className={styles.header}>
          <h2>Hot Deals</h2>
        </div>
        
        <div className={styles.hotDeals}>
          {storesHotDealsDetails.map((storesHotDealsDetail) => {
            return (
              <HotDealsListItem
                key={storesHotDealsDetail.store_id}
                couponDetails={storesHotDealsDetail}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default HotDeals