import React from 'react'

import styles from './UserMenu.module.scss'

import Button from '../Button'

const MAXIMUM_CHARACTERS = 20

const UserMenuButton = ({ currentUser, onBlur, onClick, legend }) => {
  const nickname = currentUser.nickname.substring(0, MAXIMUM_CHARACTERS)

  return (
    <Button
      className={styles.chevronButton}
      aria-label="Toggle Dropdown Menu"
      variant="transparent"
      onBlur={onBlur}
      onClick={onClick}
      isDisabled={false}
    >
      {legend}

      <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
      
      <span>
        {nickname}
      </span>

      <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </Button>
  )
}

export default UserMenuButton
