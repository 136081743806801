import React, { useState } from 'react'
import { Row, Col } from 'react-grid-system'
import cx from 'classnames'

import styles from './MyNetwork.module.scss'

import AccountSidebar from '../AccountSidebar'
import Layout from '../Layout'
import Pagination from '../Pagination'

const CURRENT_PAGE = 1
const MIN_NETWORK_COUNT = 1
const PAGE_SIZE = 20

const MyNetwork = ({ user, userCurrency, networkCount, totalAmountEarned, rippleCount, children }) => {
  const [currentPage, setCurrentPage] = useState(CURRENT_PAGE)
  const filteredUsers = children.filter(child => child.public_display_name)
  const paginatedUsers = filteredUsers.slice((currentPage - 1) * PAGE_SIZE, (currentPage * PAGE_SIZE))
  const totalPages = Math.ceil(filteredUsers.length / PAGE_SIZE)

  return (
    <Layout>
      <Row>
        <Col lg={4} xxl={4}>
          <AccountSidebar
            user={user}
            userCurrency={userCurrency}
            networkCount={networkCount}
            totalAmountEarned={totalAmountEarned}
          />
        </Col>

        <Col lg={8} xxl={8}>
          <div className={styles.heading}>
            <h1>My Network</h1>
          </div>

          <div className={styles.networkContainer}>
            <div className={styles.network}>
              <div className={cx(styles.networkCircle, styles.keyFour)}>
                <p className={cx(styles.rippleName, styles.bold)}>1st Ripple</p>
                <p className={cx(styles.rippleCount, styles.bold)}>{rippleCount[0]}</p>
              </div>

              <div className={cx(styles.networkCircle, styles.keyThree)}>
                <p className={cx(styles.rippleName, styles.bold)}>2nd Ripple</p>
                <p className={cx(styles.rippleCount, styles.bold)}>{rippleCount[1]}</p>
              </div>

              <div className={cx(styles.networkCircle, styles.keyTwo)}>
                <p className={cx(styles.rippleName, styles.bold)}>3rd Ripple</p>
                <p className={cx(styles.rippleCount, styles.bold)}>{rippleCount[2]}</p>
              </div>

              <div className={cx(styles.networkCircle, styles.keyOne)}>
                <p className={cx(styles.rippleName, styles.bold)}>4th Ripple</p>
                <p className={cx(styles.rippleCount, styles.bold)}>{rippleCount[3]}</p>
              </div>
            </div>
          </div>

          <div className={styles.userListContainer}>
            {(networkCount < MIN_NETWORK_COUNT || networkCount === MIN_NETWORK_COUNT) && (
              <h2>It looks like you're just getting started. Go to the <a href="/refer-and-earn">Refer &amp; Earn</a> page to start building your network.</h2>
            )}

            {networkCount > MIN_NETWORK_COUNT && (
              <>
                <h2>My 1st Ripple:</h2>

                <div className={styles.userList}>
                  {paginatedUsers.map(child => {
                    return (
                      <p key={child.id}>{child.public_display_name}</p>
                    )
                  })}
                </div>

                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default MyNetwork