import React, { useState } from 'react'
import { Row, Col } from 'react-grid-system'

import styles from './AccountSettings.module.scss'

import AccountSidebar from '../AccountSidebar'
import Button from '../Button'
import FlashMessage from '../FlashMessage'
import Input from '../Input'
import Layout from '../Layout'
import Select from '../Select'

const AccountSettings = ({ user, userCurrency, countries, preferredCountry, networkCount, rippleCount, totalAmountEarned, flashMessages }) => {
  const COUNTRY_OPTIONS = countries.map(({ id, name }) => {
    return {
      value: `${id}`,
      name,
    }
  })

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setPassword(newPasswordValue);

    // Show the password fields when the user enters at least 1 character.
    if (newPasswordValue.length >= 1) {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  const [nickname, setNickname] = useState(user.nickname)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [current_password, setCurrentPassword] = useState('')
  const [paypal_email, setPaypalEmail] = useState(user.paypal_email || '')
  const [preferred_country, setPreferredCountry] = useState(`${preferredCountry.id || COUNTRY_OPTIONS[0].value}`)
  const [showField, setShowField] = useState(false);

  return (
    <Layout>
      {flashMessages.length > 0 && flashMessages.map((flashMessage) => {
        const { message_type, message } = flashMessage

        return (
          <FlashMessage
            key={message}
            messageType={message_type}
            message={message}
          />
        )
      })}

      <Row>
        <Col lg={4} xxl={4}>
          <AccountSidebar
            user={user}
            userCurrency={userCurrency}
            networkCount={networkCount}
            rippleCount={rippleCount}
            totalAmountEarned={totalAmountEarned}
          />
        </Col>

        <Col lg={8} xxl={8}>
          <div className={styles.formLayoutContainer}>
            <div className={styles.heading}>
              <h1>My Profile</h1>
            </div>
            
            <div className={styles.formContainer}>
              <form method="post" action="/account" className={styles.form}>
                <input type="hidden" name="_method" value="put"></input>
                <input type="hidden" name="user[preferred_country_id]" value={preferred_country}></input>

                <div className={styles.inputContainer}>
                  <Input
                    id="user[nickname]"
                    label="What should we call you?"
                    type="text"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    placeholder="Name or Nickname"
                  />
                </div>

                <div className={styles.inputContainer}>
                  <Input
                    id="user[email]"
                    label="Email Address"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                  />
                </div>

                <div className={styles.inputContainer}>
                  <div className={styles.passwordInput}>
                    <Input
                      id="user[password]"
                      label="Change my Password"
                      type="password"
                      value={password}
                      onChange={(e) => handleNewPasswordChange(e)}
                      placeholder="New password"
                    />
                  </div>
                </div>

                {showField ? (<div className={styles.inputContainer}>
                  <div className={styles.passwordInput}>  
                    <Input
                      id="user[password_confirmation]"
                      type="password"
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      value={password_confirmation}
                      placeholder="New password confirmation"
                    />
                  </div>
                </div>) : null }

                {showField ? (<div className={styles.inputContainer}>
                  <Input
                    id="user[current_password]"
                    label="Current Password (required when changing your password)"
                    type="password"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={current_password}
                    placeholder="Current password"
                  />
                </div>) : null }

                <div className={styles.inputContainer}>
                  <Select
                    labelId="preferred_country"
                    label="Preferred Country"
                    onChange={({ value }) => setPreferredCountry(value)}
                    initialValue={preferred_country}
                    options={COUNTRY_OPTIONS}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <Input
                    id="user[paypal_email]"
                    label="Paypal Email"
                    type="email"
                    onChange={(e) => setPaypalEmail(e.target.value)}
                    value={paypal_email}
                    placeholder="Paypal Email"
                  />
                </div>

                <div className={styles.buttonContainer}>
                  <Button variant="primary" type="submit" onClick={() => {}}>Update</Button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default AccountSettings